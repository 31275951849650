import React, { useState, useEffect } from 'react';

// Functional Libraries

// Contexts
import { useAuth } from '../context/auth';

// Constants
import { catchMsg } from '../constants';

// Components
import {
  Layout,
  Typography,
  PageHeader,
  Card,
  Button,
  Modal,
  Space,
  Tooltip,
  Row,
  Col,
  Tabs,
  Steps,
  message,
  notification,
  Avatar,
  Radio,
  Badge
} from 'antd';

// Images/Icon
import { ClockCircleOutlined, TeamOutlined, DollarOutlined, SmileOutlined } from '@ant-design/icons';

// Styesheets
import './Less/partnerships.less';

// Sub components
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;
const { TabPane } = Tabs;
const { Step } = Steps;


export default function Activities({ hosted, location }) {
  const { api } = useAuth();

  const [activeEvent, setActiveEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [timeVisible, setTimeVisible] = useState(false);
  const [timeFrame, setTimeFrame] = useState();
  const [tab, setTab] = useState('1');

  // Runs every time props.location changes
  useEffect(() => {
    const selected = new URLSearchParams(location.search).get('uid');
    if (selected) {
      // Search hosted
      hosted.every((activity) => {
        if (activity.uid === selected) {
          setActiveEvent(activity);
        }
      });
    }
  }, [location]);

  const hostedEventRequest = () => {
    setLoading(true);
    api
      .post('hostedRequest', {
        title: activeEvent.title,
        timeFrame,
      })
      .then((res) => {
        if (res.data.message === 'Success') {
          setTimeVisible(false);
          setActiveEvent({});
          notification.success({
            key: 'HostRequest',
            message: 'Success',
            description: 'We will email you shortly to schedule the event',
            placement: 'topRight',
            icon: <SmileOutlined />,
          });
        } else {
          message.warning(res.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(catchMsg(err));
        setLoading(false);
      });
  };

  return (
    <div style={{ minHeight: 'calc(100vh - 42px)' }} className="pageMargin">
      <Header style={{ padding: '20px 0' }}>
        <Title level={3}>Afino Partners</Title>
      </Header>
      <Content>
        <PageHeader
          style={{ paddingLeft: 0, }}
          title="Professionally Hosted Events"
          subTitle="These premium events are hosted by Afino's professional partners"
        />
        <div className="hosted-cardWrap">
          {hosted.map((activity) => (
            <Badge count={activity.badge} offset={[-15, 10]}>
              <Card
                hoverable
                className="host-card"
                key={activity.uid}
                cover={<img className="host-card-img" alt="event" src={activity.photos[0]} />}
                onClick={() => setActiveEvent(activity)}
              >
                <Meta className="title" avatar={<img style={{ width: 55, borderRadius: 25 }} src={activity.headshot} />} title={activity.title} description={
                  <>
                  <Tooltip title="Approximate Duration">
                    <Text>
                      <ClockCircleOutlined style={{ color: '#5D22A1' }} />
                      {" "+activity.time} min
                    </Text>
                  </Tooltip>
                  <Tooltip title="Max Number of People">
                    <Text>
                      <TeamOutlined style={{ color: '#5D22A1', marginLeft: 6 }} />
                      {" "+activity.people}
                    </Text>
                  </Tooltip>
                  <Tooltip title="Cost (CAD)">
                    <Text>
                      <DollarOutlined style={{ color: '#5D22A1', marginLeft: 6 }} />
                      {" "+activity.cost}
                    </Text>
                  </Tooltip>
                  </>
                }
                />
              </Card>
            </Badge>
          ))}
        </div>
      </Content>

      {/* Event popup */}
      <Modal
        style={{ top: 20 }}
        className="eventPopup"
        onCancel={() => {
          setActiveEvent({});
          setTab('1');
        }}
        visible={activeEvent.title}
        footer={
          <Button
            disabled={activeEvent.title && activeEvent.title.includes('Coming Soon')}
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => setTimeVisible(true)}
          >
            Select Time
          </Button>
        }
      >
        <Tabs tabPosition="top" defaultActiveKey={'1'} activeKey={tab} onChange={setTab}>
          <TabPane tab={activeEvent.title} key="1">
            {/*(activeEvent.tags &&
              activeEvent.tags.map((tag) => (
                <Tooltip key={tag} placement="top" overlayStyle={{ width: 150 }} title={tagInfo[tag]}>
                  <Tag color="purple" style={{ margin: '7px 3px' }}>
                    {tag}
                  </Tag>
                </Tooltip>
              ))*/}
            <br />
            <Space direction="vertical">
              <div className="responsive">
                <iframe
                  allowFullScreen
                  src={activeEvent.video}
                  title="Instruction Video"
                  frameBorder="0"
                  style={{ borderRadius: 15, border: 0, width: '100%', minHeight: 300 }}
                ></iframe>
              </div>
              <Row gutter={[2, 0]}>
                <Col>
                  <Tooltip title="Approximate Duration">
                    <Text>
                      <ClockCircleOutlined style={{ color: '#5D22A1', margin: '0 6px 0 0' }} />
                      {activeEvent.time} min
                    </Text>
                  </Tooltip>
                  <Tooltip title="Number of People">
                    <Text>
                      <TeamOutlined style={{ color: '#5D22A1', margin: '0 6px 0 16px' }} />
                      {activeEvent.people}
                    </Text>
                  </Tooltip>
                  <Tooltip title="Cost (CAD)">
                    <Text>
                      <DollarOutlined style={{ color: '#5D22A1', margin: '0 6px 0 16px' }} />
                      {activeEvent.cost}
                    </Text>
                  </Tooltip>
                </Col>
              </Row>
              <Text style={{ whiteSpace: 'pre-wrap' }}>{activeEvent.about}</Text>
            </Space>
          </TabPane>
          <TabPane tab="How To" key="2">
            <Steps direction="vertical">
              {activeEvent.steps &&
                activeEvent.steps.map((step, ind) => <Step key={ind} title={step.name} description={step.description} status="process" />)}
            </Steps>
          </TabPane>
          <TabPane tab="About the host" key="3">
            <Row>
              <Col span={3}>
                <Avatar size={74} src={activeEvent.headshot} />
              </Col>
              <Col span={24} style={{ marginTop: 5 }}>
                <Title level={5}>{activeEvent.hostName}</Title>
              </Col>
            </Row>
            <Row>
              <Col span={24} offset={0}>
                <Text style={{ whiteSpace: 'pre-wrap' }}>{activeEvent.hostInfo}</Text>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Modal>

      {/* Select a timeframe for a hosted event*/}
      <Modal
        centered
        title="Select Time"
        onCancel={() => setTimeVisible(false)}
        visible={timeVisible}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={() => setTimeVisible(false)}>
            Back
          </Button>,
          <Button disabled={loading} key="submit" type="primary" loading={loading} onClick={hostedEventRequest}>
            Host
          </Button>,
        ]}
      >
        <Text type="secondary">When are you thinking of hosting this event?</Text>
        <Radio.Group buttonStyle="solid" style={{ paddingTop: 20 }} onChange={({ target }) => setTimeFrame(target.value)}>
          <Radio.Button style={{ marginRight: 10 }} value="Two Weeks">
            Two Weeks
          </Radio.Button>
          <Radio.Button style={{ marginRight: 10 }} value="This Month">
            This Month
          </Radio.Button>
          <Radio.Button style={{ marginRight: 10 }} value="Next Three Months">
            Next Three Months
          </Radio.Button>
        </Radio.Group>
      </Modal>
    </div>
  );
}
