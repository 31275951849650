import React, { lazy, useState, useEffect, useReducer } from 'react';

// Functional Libraries
//import DayUtils from '@date-io/dayjs';

// Contexts
//import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useAuth } from '../context/auth';

// Constants

// Components
import Footer from '../components/footer';
import { Layout } from 'antd';
// All pages within siderpages
import NavMenu from '../components/navmenu';
import Dashboard from './dashboard';
import Activities from './activities';
import Partnerships from './partnerships';
import Settings from './settings';
import Administration from './administration';

const Analytics = lazy(() => import('./analytics'));
const Surveys = lazy(() => import('./surveys'));

// Styesheets
import './Less/index.less';

// Sub components

function comingUpReducer(state, action) {
  if (action.do === 'add') {
    return state.concat(action.activity);
  } else if (action.do === 'remove') {
    return state.filter(activity => activity._id !== action.id);
  } else {
    return state;
  }
}

export default function SiderPages(props) {
  // Auth Tokens
  const { api } = useAuth();
  const [comingUp, setComingUp] = useReducer(comingUpReducer, []);
  const [pastEvents, setPastEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hosted, setHosted] = useState([]);
  const [analytics, setAnalytics] = useState({
    suggestions: [],
    snapshots: [],
    pastEvents: [],
    pastSurveys: [],
  });
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    // Set default axios auth headers to tokens
    api.get('comingUp').then(res => setComingUp({ do: 'add', activity: res.data }));
    const prom0 = api.get('pastEvents');
    prom0.then(res => setPastEvents(res.data));
    api.get('categories').then(res => {
      setCategories(res.data);
    });

    api.get('hosted').then(res => setHosted(res.data));

    const prom3 = api.get('snapshot');
    const prom4 = api.get('pastSurveys');

    Promise.all([prom0, prom3, prom4]).then(resArr => {
      setAnalytics({
        pastEvents: resArr[0].data,
        suggestions: {},
        snapshots: resArr[1].data,
        pastSurveys: resArr[2].data,
      });
    });

    api.get('surveys').then(res => setSurveys(res.data));
  }, []);

  function getEvent(uid) {
    let match;
    if (!uid) return {};
    if (uid.includes('h')) {
      match = hosted.find(activity => activity.uid === uid);
    } else {
      match = categories.map(({ events }) => events).flat();
      match = match.find(activity => activity.uid == uid);
      console.log('Found ', match);
    }
    return match || {};
  }

  const navigation = {
    '/dashboard': '1',
    1: <Dashboard {...props} key={comingUp} pastEvents={pastEvents} comingUp={comingUp} setComingUp={setComingUp} getEvent={getEvent} />,
    '/engage': '2',
    2: <Activities {...props} categories={categories} setComingUp={setComingUp} getEvent={getEvent} />,
    '/partnerships': '3',
    3: <Partnerships {...props} hosted={hosted} />,
    '/settings': '4',
    4: <Settings {...props} />,
    '/surveys': '6',
    6: <Surveys {...props} key={surveys} surveys={surveys} />,
    '/analytics': '7',
    7: <Analytics key={analytics} data={analytics} />,
    '/administration': '8',
    8: <Administration />,
  };
  const selected = navigation[props.location.pathname];

  return (
    <Layout>
      <NavMenu selected={selected} />
      <Layout className="site-layout">
        {navigation[selected]}
        <Footer />
      </Layout>
    </Layout>
  );
}

/* Tour
<Modal
  visible={tour.open}
  title="Welcome to Afino"
  centered
  onCancel={() => setTour(false)}
  footer={<Button onClick={() => setTour(false)}>Skip</Button>}
  width="80vw"
  bodyStyle={{ height: '80vh' }}
>
  <iframe
    allowFullScreen
    src={tour.link}
    title="Instruction Video"
    frameBorder="0"
    style={{ borderRadius: 15, border: 0, width: '100%', height: '100%' }}
  ></iframe>
</Modal>*/

/*
<MuiPickersUtilsProvider utils={DayUtils}>

</MuiPickersUtilsProvider>
*/
