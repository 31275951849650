import React, { Suspense, lazy, useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// Functional Libraries
import axios from 'axios';

// Contexts
import { AuthContext, useAuth } from './context/auth';

// Constants
import { APIbaseURL } from './constants';

// Components
import { message } from 'antd';

// Images/Icon
import { LoadingOutlined } from '@ant-design/icons';

// Styesheets
import 'antd/dist/antd.less';

// Sub components
import SiderPages from './SiderPages';

// Lazy loaded components
const Auth = lazy(() => import('./Auth'));
const Signin = lazy(() => import('./Auth/signin'));
const Signup = lazy(() => import('./Auth/signup'));
const Forgot = lazy(() => import('./Auth/forgot'));
//const Verify = lazy(() => import('./Auth/verify'));

const Slack = lazy(() => import('./Slack'));
const Pay = lazy(() => import('./Slack/pay'));

//const SiderPages = lazy(() => import('./SiderPages'));

const Engage = lazy(() => import('./Engage'));
const EventLanding = lazy(() => import('./Engage/eventLanding'));
const Leaderboard = lazy(() => import('./Engage/leaderboard'));
const Questions = lazy(() => import('./Engage/questions'));
const Pictures = lazy(() => import('./Engage/pictures'));
const Upvote = lazy(() => import('./Engage/upvote'));
const Hosted = lazy(() => import('./Engage/hosted'));
const Survey = lazy(() => import('./Engage/survey'));
const EventFeedback = lazy(() => import('./Engage/feedback'));
const Admin = lazy(() => import('./Admin'));
const FourOhFour = lazy(() => import('./404'));

// Message to anyone inspecting the game through dev tools
console.log(
  "%cHey there curious techy friend!!\nFind something odd or have a suggestion for improvements. I'd love to hear from you! Shoot me an email: devin@afino.io",
  'color: #6AE9B2; font-size:18px'
);

function PrivateRoute({ render: Render, ...rest }) {
  let { idToken } = useAuth();
  return (
    <Route
      {...rest}
      render={props => (idToken ? <Render {...props} /> : <Redirect to={{ pathname: '/signin', state: { from: window.location.pathname } }} />)}
    />
  );
}

// This is just to avoid unneccesary api calls
const noAuthPages = ['/signup', '/verify', '/forgot', '/pictures', '/event', '/join', '/feedback', '/survey'];

export default function App() {
  const [idToken, setIdToken] = useState(false);
  const [accessToken, setAccessToken] = useState(false);
  const [company, setCompany] = useState();
  const [checkingAuth, setCheckingAuth] = useState(true);
  
  const api = useMemo(() => {
    return axios.create({
      withCredentials: APIbaseURL.includes('localhost') ? false : true,
      baseURL: APIbaseURL,
      headers: {
        common: {
          Authorization: `token ${idToken}`,
        },
      },
    });
  }, [idToken]);

  useEffect(() => {
    // If the page does not need auth don't bother checking
    if (noAuthPages.includes(window.location.pathname)) {
      setCheckingAuth(false);
      return;
    }
    api
      .get('checkAuth')
      .then(res => {
        if (res.data.message === 'Success') {
          setIdToken(res.data.idToken);
          setAccessToken(res.data.accessToken);
          setCompany(res.data.company);
          setCheckingAuth(false);
        } else {
          setCheckingAuth(false);
        }
      })
      .catch(err => {
        setCheckingAuth(false);
        if ((err && err.response && err.response.status !== 401) || !err.response) {
          message.error('We had issues connecting to the server. Contact team@afino.io if this issue keeps up', 5);
        }
      });
  }, []);

  return (
    <>
      {checkingAuth ? (
        <LoadingOutlined style={{ fontSize: 45, marginLeft: '47vw', marginTop: '45vh' }} />
      ) : (
        <AuthContext.Provider value={{ idToken, setIdToken, accessToken, setAccessToken, api, company, setCompany }}>
          <Router>
            <Suspense fallback={<LoadingOutlined style={{ fontSize: 45, marginLeft: '47vw', marginTop: '45vh' }} />}>
              <Switch>
                {/* Login page */}
                <Route
                  path="/signin"
                  render={props => (
                    <Auth>
                      <Signin {...props} />
                    </Auth>
                  )}
                />
                {/* Page to signup. Requires access code */}
                <Route
                  path="/signup"
                  render={props => (
                    <Auth>
                      <Signup {...props} />
                    </Auth>
                  )}
                />
                {/*
              <Route path="/verify" render={(props) => (
                <Auth><Verify {...props} /></Auth>
              )} />   {/* Verify from email */}
                {/* Forgot password page */}
                <Route
                  path="/forgot"
                  render={props => (
                    <Auth>
                      <Forgot {...props} />
                    </Auth>
                  )}
                />
                {/* Add to slack page */}
                <Route
                  path="/slack"
                  render={props => (
                    <Auth>
                      <Slack {...props} />
                    </Auth>
                  )}
                />
                {/* Page for paying */}
                <Route
                  path="/doodle_subscribe"
                  render={props => (
                    <Auth>
                      <Pay {...props} />
                    </Auth>
                  )}
                />
                {/* Landing page for activities */}
                <Route
                  path="/event"
                  render={props => (
                    <Engage>
                      <EventLanding {...props} />
                    </Engage>
                  )}
                />
                {/* Page for hosted events */}
                <Route
                  path="/hosted"
                  render={props => (
                    <Engage>
                      <EventLanding {...props} />
                    </Engage>
                  )}
                />
                {/* Leaderboard page for company */}
                <Route
                  path="/leaderboard"
                  render={props => (
                    <Engage>
                      <Leaderboard {...props} />
                    </Engage>
                  )}
                />
                {/* Page for joining hosted video call */}
                <Route
                  path="/join"
                  render={props => (
                    <Engage>
                      <Hosted {...props} />
                    </Engage>
                  )}
                />
                {/* Page for ultimate question game */}
                <Route
                  path="/ponder"
                  render={props => (
                    <Engage>
                      <Questions {...props} />
                    </Engage>
                  )}
                />
                {/* Page for picture sharing activities */}
                <Route
                  path="/pictures"
                  render={(props) => (
                    <Pictures {...props} />
                  )}
                />
                {/* Page for upvoting activities */}
                <Route
                  path="/ideas"
                  render={(props) => (
                    <Upvote {...props} />
                  )}
                />
                {/* Page for employees to fill out surveys */}
                <Route
                  path="/survey"
                  render={props => (
                    <Engage>
                      <Survey {...props} />
                    </Engage>
                  )}
                />
                {/* Page after event completed. To get feedback on how it went. */}
                <Route
                  path="/feedback"
                  render={props => (
                    <Engage>
                      <EventFeedback {...props} />
                    </Engage>
                  )}
                />
                {/* All sider pages */}
                <PrivateRoute
                  path={['/dashboard', '/engage', '/partnerships', '/settings', '/analytics', '/surveys', '/administration']}
                  render={props => <SiderPages {...props} />}
                />
                {/* Redirect to dashboard */}
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                {/* Admin page for viewing metrics and generate sign up code */}
                <PrivateRoute
                  path="/admin"
                  render={props => (
                    <Auth>
                      <Admin {...props} />
                    </Auth>
                  )}
                />
                {/* Page if path not defined */}
                <Route component={FourOhFour} />
              </Switch>
            </Suspense>
          </Router>
        </AuthContext.Provider>
      )}
    </>
  );
}
