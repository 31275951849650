import React, { useState } from 'react';

// Functional Libraries
// Contexts

// Constants
import { whoGoesFirst, ultimateQuestionList } from '../constants';

// Images/Icon

// Components
import { Typography, Input, Button, Form } from 'antd';

// Images/Icon

// Styesheets
import './Less/toolbox.less';

// Sub components
const { Title, Text } = Typography;

const rules = [
  { pattern: /[^,]$/, message: <span style={{ color: '#5d22a1' }}>Enter names seperated by commas with no trailing commas</span>  },
];

function randEl(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function RandWho({ white }) {
  const [who, setWho] = useState(randEl(whoGoesFirst));

  const genWho = () => {
    setWho(randEl(whoGoesFirst));
  };

  return (
    <>
      <Title level={5} style={{ color: white && "#fff" }}>
        {who}
      </Title>
      <Button type="primary" onClick={genWho} style={{ marginBottom: 20 }}>
        Who Goes First?
      </Button>
    </>
  );
}

export function NameList({ white }) {
  const [nameList, setNameList] = useState([]);

  const orderList = values => {
    setNameList(shuffle(values.nameList.replace(/ /g, '').split(',')));
  };

  return (
    <>
      <Form name="names" onFinish={orderList}>
        <Form.Item name="nameList" className={white && 'label-white'} label="Names" rules={rules} validateTrigger="onBlur">
          <Input.TextArea style={{ maxHeight: 100, width: '90%' }} placeholder="Devin, Paul, Sarah, Javier" autoSize={{ minRows: 1 }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Shuffle
          </Button>
        </Form.Item>
      </Form>
      {nameList.length > 0 && nameList[0] !== "" && <Text className={white && "white-order"} copyable>{nameList.map(name => (
        name+", "
      ))}</Text>}
    </>
  );
}

export function UltimateQuestion({ white }) {
  const [question, setQuestion] = useState(randEl(ultimateQuestionList));

  const genQues = () => {
    setQuestion(randEl(ultimateQuestionList));
  };

  return (
    <>
      <Text copyable className={white && "question-white"}>{question}</Text><br />
      <Button type="primary" style={{ marginTop: 10 }} onClick={genQues}>
        New Question
      </Button>
    </>
  );
}
