import React from 'react';

// Functional Libraries
// Contexts
// Constants

// Components
import { Layout } from 'antd';

// Images/Icon
// Styesheets

// Sub components
const { Footer } = Layout;

export default function Foot() {
  return <Footer style={{ textAlign: 'center', padding: '10px 0', backgroundColor: '#f6f5f5' }}>Afino ©2021 All Rights Reserved</Footer>;
}
