import React, { useState, useEffect } from 'react';

// Functional Libraries
import day from 'dayjs';

// Contexts
import { useAuth } from '../context/auth';

// Constants
import { catchMsg } from '../constants';

// Components
import { Layout, Typography, PageHeader, Card, Modal, Statistic, Row, Col, Rate, List, message } from 'antd';
import { ChartCard, Bar, WaterWave } from 'ant-design-pro/lib/Charts';

// Images/Icon

// Styesheets
import 'ant-design-pro/dist/ant-design-pro.css';
import './Less/analytics.less';

// Sub components
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;

export default function Analytics() {
  const { api } = useAuth();
  const [teams, setTeams] = useState({});
  const [analytics, setAnalytics] = useState({ pastEvents: [], pastSurveys: [] });

  useEffect(() => {
    api
      .get('get_teams')
      .then(res => {
        if (res.data.message === 'Success') {
          setTeams(res.data.teams);
        } else {
          message.info(res.data.message);
        }
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
  }, []);

  const get_team_data = (teamId) => {
    if (teamId === analytics.teamId) return
    api
      .get('get_team_data', {
        params: {
          teamId,
        },
      })
      .then(res => {
        // Set events and survey data
        setAnalytics({ teamId, pastEvents: res.data.events, pastSurveys: res.data.surveys });
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
  };

  const name_team = (name, teamId) => {
    if (teams[teamId].name === name || name === "") {
      return
    }
    api
      .post('name_team', {
        name,
        teamId
      })
      .then(res => {
        if (res.data.message) {
          message.success("Successfully updated team name");
          let updateTeams = {...teams, [teamId]: { ...teams[teamId], name }}
          setTeams(updateTeams);
        } else {
          message.info(res.data.message);
        }
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
  };

  const { pastEvents, pastSurveys } = analytics;

  const [activeEvent, setActiveEvent] = useState({});
  const [activeSurvey, setActiveSurvey] = useState({});

  // Note start and end are to the nearest 10 seconds hence /360 to get hours
  const pastEventsTime = pastEvents.reduce((accum, { start, end }) => end - start + accum, 0) / 360;
  const eventRatingTotal = activeEvent.rating
    ? Object.entries(activeEvent.rating).reduce((accum, [key, value]) => accum + parseInt(key) * value, 0)
    : 0;
  const eventResponseNum = activeEvent.rating ? Object.values(activeEvent.rating).reduce((accum, el) => accum + el, 0) : 0;
  const responseRate = () => {
    if (activeEvent.attendance > 0 && eventResponseNum <= activeEvent.attendance) {
      return (eventResponseNum / activeEvent.attendance) * 100;
    } else if (activeEvent.attendance === 0 && eventResponseNum === 0) {
      return 0;
    } else return 100;
  };
  const surveyResponseNum = pastSurveys.reduce((accum, { responseNum }) => accum + responseNum, 0);

  function answerAverage(answer) {
    let responses = [];
    Object.entries(answer).forEach(([key, value]) => {
      if (!isNaN(parseInt(key))) {
        responses.push({ number: value, worth: parseInt(key) * value });
      }
    });
    console.log(responses);
    return Math.ceil(responses.reduce((accum, { worth }) => accum + worth, 0) / responses.reduce((accum, { number }) => accum + number, 0) * 10) / 10;
  }

  return (
    <div className="pageMargin" style={{ minHeight: 'calc(100vh - 42px)' }}>
      <Header style={{ padding: '20px 0' }}>
        <Title level={3}>Team Break Downs</Title>
      </Header>
      <Content style={{ overflow: 'initial' }}>
        <Row gutter={[16, 16]}>
          {Object.entries(teams).map(([key, team]) => (
            <Col xs={24} md={6}>
              <Card hoverable style={{ borderRadius: 8 }} onClick={() => get_team_data(key)}>
                <Title editable={{ onChange: (value) => name_team(value, key), maxLength: 50 }} level={5}>{team.name}</Title>
                <Text strong>Admins: </Text><Text>{team.admins.join(", ")}</Text>
              </Card>
            </Col>
          ))}
        </Row>
        <PageHeader title="Engagement Analytics" style={{ paddingTop: 24, paddingLeft: 0 }} />
        <Card className="header_card">
          <Statistic title="Activities Hosted" value={pastEvents.length} precision={0} suffix="Activities" />
        </Card>
        <Card className="header_card">
          <Statistic title="Total Activity Hours" value={pastEventsTime} precision={2} suffix="hrs" />
        </Card>
        <div className="past-scroller">
          {pastEvents.map(pastEvent => (
            <Card hoverable className="past-event-card" key={pastEvent.uid} onClick={() => setActiveEvent(pastEvent)}>
              <Meta className="title" title={pastEvent.title} description={day.unix(pastEvent.start * 10).format('ddd MMM D, YYYY')} />
            </Card>
          ))}
        </div>
        <PageHeader title="Survey Analytics" style={{ paddingTop: 24, paddingLeft: 0 }} />
        <div style={{ minHeight: 175, background: 'none' }}>
          <Card className="header_card">
            <Statistic title="Total Responses" value={surveyResponseNum} precision={0} />
          </Card>
          <Card className="header_card">
            <Statistic title="Surveys Sent" value={pastSurveys.length} precision={0} />
          </Card>
          <div className="past-scroller">
            {pastSurveys.map(survey => (
              <Card hoverable className="past-event-card" onClick={() => setActiveSurvey(survey)}>
                <Meta title={survey.title} description={day.unix(parseInt(survey._id.substring(0, 8), 16)).format('ddd MMM D, YYYY')} />
              </Card>
            ))}

            {/* This is the modal for viewing past events */}
            <Modal
              centered
              title={activeEvent.title}
              visible={activeEvent.title}
              onCancel={() => setActiveEvent({})}
              className="analyzeModal"
              footer={null}
              style={{ marginTop: 0 }}
            >
              {activeEvent.title && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} lg={7} xl={6}>
                      <ChartCard
                        title="Rating"
                        total={eventRatingTotal > 0 ? (eventRatingTotal / eventResponseNum).toFixed(1) : 0}
                        contentHeight={60}
                        bordered={false}
                      >
                        <Rate disabled allowHalf value={eventResponseNum > 0 ? eventRatingTotal / eventResponseNum : 0} />
                      </ChartCard>
                      <ChartCard bordered={false} contentHeight={160} title="Response Rate">
                        <WaterWave height={140} color={'#5d22a1'} percent={responseRate()} />
                      </ChartCard>
                    </Col>
                    <Col xs={24} sm={24} lg={17} xl={18}>
                      <ChartCard
                        title="Rating Distribution"
                        footer={'Total Responses:  ' + eventResponseNum}
                        contentHeight={255}
                        className="noShowGraph"
                      >
                        <Bar
                          height={230}
                          data={[
                            { x: '⭐️', y: activeEvent.rating['1'] },
                            { x: '⭐️ ⭐️', y: activeEvent.rating['2'] },
                            { x: '⭐️ ⭐️ ⭐️', y: activeEvent.rating['3'] },
                            { x: '⭐️ ⭐️ ⭐️ ⭐️', y: activeEvent.rating['4'] },
                            { x: '⭐️ ⭐️ ⭐️ ⭐️ ⭐️', y: activeEvent.rating['5'] },
                          ]}
                          color="#5d22a1"
                          padding={[10, 58, 50, 40]}
                        />
                      </ChartCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <ChartCard title="Feedback" bordered={false}>
                        <List
                          className="comment-list"
                          header=""
                          itemLayout="horizontal"
                          dataSource={activeEvent.feedback}
                          renderItem={item => <List.Item>{item}</List.Item>}
                        />
                      </ChartCard>
                    </Col>
                  </Row>
                </>
              )}
            </Modal>

            {/* This is the modal for viewing past surveys */}
            <Modal
              centered
              title={
                activeSurvey.title && activeSurvey.title + ' on  ' + day.unix(parseInt(activeSurvey._id.substring(0, 8), 16)).format('MMM D YYYY')
              }
              visible={activeSurvey.title}
              onCancel={() => setActiveSurvey({})}
              className="analyzeModal"
              footer={null}
            >
              <Row>
                <Col span={24}>
                  <Text strong>Total Responses: {activeSurvey.responseNum}</Text>
                  <div style={{ padding: 20 }} className="noShowGraph">
                    {activeSurvey.answers &&
                      activeSurvey.answers.map(answer => (
                        <div style={{ paddingBottom: 20 }}>
                          <Title level={5}>{answer.q}</Title>
                          <ChartCard title={'Average Rating: ' + answerAverage(answer)} contentHeight={160}>
                            <Bar
                              height={120}
                              data={[
                                { x: 'Strongly Disagree', y: answer['1'] },
                                { x: 'Disagree', y: answer['2'] },
                                { x: 'Neutral', y: answer['3'] },
                                { x: 'Agree', y: answer['4'] },
                                { x: 'Strongly Agree', y: answer['5'] },
                              ]}
                              color="#5d22a1"
                              padding={[10, 55, 50, 40]}
                            />
                          </ChartCard>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </Modal>
          </div>
        </div>
      </Content>
    </div>
  );
}

/*
function filterAnalytics(action) {
  if (action === 'all' && analytics.filtered) {
    setAnalytics({
      pastEvents: data.pastEvents,
      pastSurveys: data.pastSurveys,
    });
  } else if (action === 'three') {
    setAnalytics({
      pastEvents: data.pastEvents.filter(pastEvent => pastEvent.end > Math.round(Date.now() / 10000) - 789000),
      pastSurveys: data.pastSurveys.filter(survey => parseInt(survey._id.substring(0, 8), 16) > Math.round(Date.now() / 1000) - 7890000),
      filtered: true,
    });
  } else if (action === 'one') {
    setAnalytics({
      pastEvents: data.pastEvents.filter(pastEvent => pastEvent.end > Math.round(Date.now() / 10000) - 2630000),
      pastSurveys: data.pastSurveys.filter(survey => parseInt(survey._id.substring(0, 8), 16) > Math.round(Date.now() / 1000) - 2630000),
      filtered: true,
    });
  }
}

<Row>
  <Col xs={24} md={14}>
  </Col>
  <Col xs={24} md={10} style={{ textAlign: 'right' }}>
    <Radio.Group defaultValue="all" buttonStyle="solid" onChange={({ target: { value } }) => filterAnalytics(value)}>
      <Radio.Button value="all">All Time</Radio.Button>
      <Radio.Button value="one">1 Month</Radio.Button>
      <Radio.Button value="three">3 Months</Radio.Button>
    </Radio.Group>
  </Col>
</Row>
*/
