import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Functional Libraries
// Contexts
// Constants

// Components
import { Layout, Menu, Badge } from 'antd';

// Images/Icon
import { BarChartOutlined, RocketOutlined, HeartOutlined, UserOutlined, HomeOutlined, ExperimentOutlined, StarOutlined, LockOutlined } from '@ant-design/icons';

// Stylesheets

// Sub components
const { Sider } = Layout;

const logoPath = {
  small: 'logo/cactus.svg',
  big: 'logo/afino_logo.svg',
};

export default function NavMenu({ selected }) {
  const [logo, setLogo] = useState('big');
  const [leftMarg, setLeftMarg] = useState(window.innerWidth > 575 ? 200 : 80);
  return (
    <>
    <div style={{ width: leftMarg, height: '100%' }}>
    </div>
    <Sider
      theme="light"
      collapsible
      breakpoint="lg"
      onCollapse={(collapsed) => {
        setLogo(collapsed ? 'small' : 'big');
        setLeftMarg(collapsed ? 80 : 200);
      }}
      style={{ overflow: 'auto', height: '100vh', position: 'fixed', zIndex: 10 }}
    >
      <div className="logo-sider">
        <img id="logo" src={logoPath[logo]} alt="logo" style={{ height: '100%', maxWidth: 100 }} />
      </div>
      <Menu selectedKeys={[selected]} mode="inline" style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<RocketOutlined />}>
          <Link to="/engage">Engage</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<StarOutlined />}>
          <Link to="/partnerships">Hosted</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<UserOutlined />}>
          <Link to="/settings">
            <Badge count="" offset={[15, 2]}>
              User
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.SubMenu title="Beta Tools" key="5" icon={<ExperimentOutlined />}>
          <Menu.Item key="6" icon={<HeartOutlined />}>
            <Link to="/surveys">Monitor</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<BarChartOutlined />}>
            <Link to="/analytics">Analyze</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<LockOutlined />}>
            <Link to="/administration">Admin</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Sider>
    </>
  );
}
