import React, { useState, useReducer, useEffect } from 'react';

// Functional Libraries
import day from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
//import { createMuiTheme } from '@material-ui/core/styles';

// Contexts
import { useAuth } from '../context/auth';
//import { ThemeProvider } from '@material-ui/core';

// Constants
import { catchMsg } from '../constants';

// Components
import { Typography, Button, Modal, Tooltip, Row, Col, Input, Radio, Slider, message, Table } from 'antd';
//import { DateTimePicker } from '@material-ui/pickers';

// Images/Icon
import { TeamOutlined, ClockCircleOutlined, CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Styesheets
import './Less/doodle.less';

// Sub components
const { Title, Text } = Typography;

day.extend(advancedFormat);
day.extend(utc);
day.extend(timezone);

const doodle = {
  description:
    "Let's play Doodle ✏️  ! Doodle is an online version of Pictionary where we take turns drawing words for one another to guess. The faster you guess, the more points you get! Click the link in the location to begin! <br><br>⚡️Powered by <a target='_blank' href='https://afino.io'>Afino</a>⚡️<br><br>",
};

function gameSettingsReducer(state, action) {
  if (action.difficulty) {
    return { ...state, difficulty: action.difficulty };
  } else if (action.rounds) {
    return { ...state, rounds: action.rounds };
  } else if (action.time) {
    return { ...state, time: action.time };
  } else return state;
}

export default function EngageSpotlight({ setComingUp }) {
  const { api, company } = useAuth();

  const [estimate, setEstimate] = useState({});
  const [settings, dispatchSettings] = useReducer(gameSettingsReducer, { difficulty: 'regular', rounds: 3, time: 90 });
  const [newEvent, setNewEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);

  // Get the company leaderboard
  useEffect(() => {
    api
      .get('leaderboard', {
        params: {
          company,
        },
      })
      .then(res => {
        if (res.data.message === 'Success') {
          setLeaderboard(res.data.doodle);
        } else {
          message.warning(res.data.message);
        }
      })
      .catch(err => {
        console.log('Error: ', err);
        message.error({ content: "We're working on fixing an issue", key: 'Error', duration: 4 });
      });
  }, []);

  const hostDoodle = () => {
    setLoading(true);
    api
      .post('makeEvent', {
        title: 'Doodle',
        uid: '1',
        start: Math.round(Date.now() / 10000),
        end: Math.round((Date.now() + 30 * 60000) / 10000),
        gameOptions: settings,
      })
      .then(res => {
        if (res.data.message === 'Success') {
          message.success('The event has been added');
          setNewEvent(res.data);
        } else {
          message.warning(res.data.message);
        }
        setLoading(false);
      })
      .catch(err => {
        message.error(catchMsg(err));
        setLoading(false);
      });
  };

  const pushEvent = () => {
    setComingUp({ do: 'add', activity: newEvent });
    setNewEvent({});
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => a.score - b.score,
        multiple: 1,
      },
      key: 'score',
    },
  ];
  var count = 0;
  let dataSource = Array(6).fill(null).map(() => ({
    key: 'user' + count++,
    name: '_',
    score: 0
  }));
  if (Array.isArray(leaderboard)) {
    (leaderboard.slice(0, 6)).forEach((player, ind) => {
      dataSource[ind] = {
        key: 'user' + count++,
        name: player.nickname,
        score: player.score
      };
    });
  }

  return (
    <div className="engage-spotlight" style={{ width: '100%' }}>
      <Row className="doodle-collapse" gutter={[16, 16]}>
        <Col xs={24} md={9} lg={7}>
          <Row>
            <Title level={5} style={{ paddingRight: 10 }}>
              Players
            </Title>
            <Tooltip title="Number of People">
              <Text>
                <TeamOutlined style={{ marginRight: 5, color: '#5D22A1' }} />
                2-12
              </Text>
            </Tooltip>
          </Row>
          <Row style={{ paddingTop: 10, paddingBottom: 5 }}>
            <Title level={5} style={{ paddingRight: 10, marginBottom: 0 }}>
              Words
            </Title>
            <Tooltip title="Difficulty of Words. Fair warning, expert is really hard 😅">
              <Text>
                <QuestionCircleOutlined className="info-icon" style={{ color: '#BBBBBB' }} />
              </Text>
            </Tooltip>
          </Row>
          <Row>
            <Radio.Group
              value={settings.difficulty}
              defaultValue="regular"
              buttonStyle="solid"
              size="small"
              onChange={({ target: { value } }) => dispatchSettings({ difficulty: value })}
            >
              <Radio.Button value="regular" style={{ borderRadius: 2 }} className="activities-doodle-btn">
                Regular
              </Radio.Button>
              <Radio.Button value="hard" style={{ borderRadius: 2 }} className="activities-doodle-btn">
                Hard
              </Radio.Button>
              <Radio.Button value="expert" style={{ borderRadius: 2 }} className="activities-doodle-btn">
                Expert
              </Radio.Button>
              {/*<Radio.Button value="custom" style={{ borderRadius: 2 }} className="activities-doodle-btn">
                Custom
              </Radio.Button>*/}
            </Radio.Group>
          </Row>
          <Row style={{ paddingTop: 10, paddingBottom: 5 }}>
            <Title level={5} style={{ paddingRight: 10, marginBottom: 0 }}>
              Number of Rounds
            </Title>
            <Tooltip title="How many rounds per person">
              <Text>
                <QuestionCircleOutlined className="info-icon" style={{ color: '#BBBBBB' }} />
              </Text>
            </Tooltip>
          </Row>
          <Row>
            <Radio.Group
              value={settings.rounds}
              defaultValue={3}
              buttonStyle="solid"
              size="small"
              onChange={({ target: { value } }) => dispatchSettings({ rounds: value })}
            >
              <Radio.Button value={2} style={{ borderRadius: 2 }} className="activities-doodle-btn">
                2
              </Radio.Button>
              <Radio.Button value={3} style={{ borderRadius: 2 }} className="activities-doodle-btn">
                3
              </Radio.Button>
              <Radio.Button value={4} style={{ borderRadius: 2 }} className="activities-doodle-btn">
                4
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row style={{ paddingTop: 10, paddingBottom: 5 }}>
            <Title level={5} style={{ paddingRight: 10, marginBottom: 0 }}>
              Turn Time
            </Title>
            <Tooltip title="How long people get to draw / guess">
              <Text>
                <QuestionCircleOutlined className="info-icon" style={{ color: '#BBBBBB' }} />
              </Text>
            </Tooltip>
          </Row>
          <Row>
            <Radio.Group defaultValue={90} buttonStyle="solid" size="small" onChange={({ target: { value } }) => dispatchSettings({ time: value })}>
              <Radio.Button value={45} style={{ borderRadius: 2 }} className="activities-doodle-btn">
                45s
              </Radio.Button>
              <Radio.Button value={60} style={{ borderRadius: 2 }} className="activities-doodle-btn">
                60s
              </Radio.Button>
              <Radio.Button value={90} style={{ borderRadius: 2 }} className="activities-doodle-btn">
                90s
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row style={{ paddingTop: 24 }}>
            <Button onClick={() => hostDoodle()} loading={loading} type="primary">
              Play Now!
            </Button>
          </Row>
          <Row>
            <Button
              type="text"
              onClick={() => setEstimate({ open: true, value: 6 })}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <span style={{ textDecoration: 'underline' }}>How long will this game last?</span>
            </Button>
          </Row>
        </Col>
        <Col xs={24} md={15} lg={11} style={{ textAlign: 'center', alignSelf: 'center' }}>
          <Row gutter={[16, 16]}>
            <iframe
              allowFullScreen
              src="https://www.youtube.com/embed/7Kpd3T1A2gI?rel=0"
              title="Instruction Video"
              frameBorder="0"
              className="event-video"
            />
          </Row>
        </Col>
        <Col xs={0} lg={6}>
          <Table
            className="doodle-leaderboard"
            title={() => (
              <Title level={5} style={{ color: 'white', margin: 0 }}>
                Leaderboard
              </Title>
            )}
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            bordered
          ></Table>
        </Col>
      </Row>

      <Modal visible={estimate.open} centered title="Game Time Estimate" onCancel={() => setEstimate({})} footer={null}>
        <Title level={3}>
          <ClockCircleOutlined style={{ marginLeft: 10, marginRight: 5, color: '#5D22A1' }} />
          {Math.round((estimate.value * settings.rounds * settings.time) / 60)} min
        </Title>
        <Text>
          Number of players:{' '}
          <Slider
            defaultValue="6"
            step={1}
            min={2}
            max={12}
            onAfterChange={value => setEstimate({ value, open: true })}
            marks={{
              2: { style: {}, label: '2' },
              12: { style: {}, label: '12' },
            }}
          />
        </Text>
      </Modal>

      {/* Modal for when event is added */}
      <Modal
        centered
        title={
          <Title level={4}>
            Doodle <img alt="Doodle Icon" src="https://afino-app.s3.amazonaws.com/activities/uid_1_icon.svg" style={{ width: 30 }} />
          </Title>
        }
        onCancel={pushEvent}
        visible={newEvent.title}
        footer={
          newEvent.end ? <Text type="secondary">Link Expires: {day.unix((newEvent.end + 259200) * 10).format('ddd MMM Do, hh:mm a z')}</Text> : null
        }
      >
        <Title level={5}>Send to Team</Title>
        <Input.Search
          placeholder="Event Link Appears Here"
          enterButton="Copy"
          value={window.location.origin + '/event?id=' + newEvent._id}
          suffix={<CopyOutlined />}
          onSearch={value =>
            navigator.clipboard.writeText(value).then(() => {
              message.success({ content: 'Copied!', key: 'Copy' });
            })
          }
          style={{ width: '100%', marginTop: 10, display: 'inline-block' }}
        />
        <br />
        <Title style={{ marginTop: 20 }} level={5}>
          Add to Calendar
        </Title>
        <Button
          style={{ marginTop: 10 }}
          type="primary"
          ghost
          target="_blank"
          rel="noopener noreferrer"
          href={`https://calendar.google.com/calendar/r/eventedit?text=Doodle&details=${doodle.description}&location=${
            window.location.origin + '/event?id=' + newEvent._id
          }&dates=${day.unix(newEvent.start * 10).format('YYYYMMDDTHHmmSS')}/${day
            .unix(newEvent.end * 10)
            .format('YYYYMMDDTHHmmSS')}&add=team@afino.io`}
        >
          Google
        </Button>
        <Button
          style={{ marginTop: 10, marginLeft: 10 }}
          type="primary"
          ghost
          target="_blank"
          rel="noopener noreferrer"
          href={`https://outlook.office.com/calendar/0/deeplink/compose?&subject=Doodle&body=${doodle.description}&location=${
            window.location.origin + '/event?id=' + newEvent._id
          }&startdt=${day.unix(newEvent.start * 10).format('YYYY-MM-DDTHH:mm:SS')}&enddt=${day
            .unix(newEvent.end * 10)
            .format('YYYY-MM-DDTHH:mm:SS')}&popoutv2=1&to=team@afino.io`}
        >
          Outlook
        </Button>
      </Modal>
    </div>
  );
}

/*
// Mui color theme
const theme = createMuiTheme({ palette: { primary: { main: '#5D22A1' }, secondary: { main: '#14EDAE' } } });

// Datepicker reducer
function timeReducer(state, action) {
  if (action.time) {
    return {
      start: action.start,
      end: new Date(Date.parse(action.start) + action.time * 60000),
      changed: action.changed,
    };
  } else if (!action.changed) {
    return {
      ...state,
      changed: false,
    };
  } else {
    return {
      start: action.start,
    };
  }
}

// Gallery pictures
const gallery = [Tutorial_Vid_Img, 'https://afino-app.s3.amazonaws.com/event/uid_1.jpg'];

// Mui Datepicker controller
const [timeVisible, setTimeVisible] = useState(false);
const [time, setTime] = useReducer(timeReducer, { start: new Date(Math.ceil(Date.now() / 900000) * 900000) });

//Watch for when time is updated and this will indicate an event being hosted
useEffect(() => {
  if (time.changed && time.start && time.end) {
    hostDoodle()
  }
}, [time]);

// Remove body scroll lock if no modal open. Otherwise lock. Temp solution
useEffect(() => {
  const checkScroll = setInterval(() => {
    if (timeVisible || Object.keys(newEvent).length > 0) {
      document.body.style.overflow = 'hidden';
      clearInterval(checkScroll);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, 50);
  setTimeout(() => {
    clearInterval(checkScroll);
  }, 1000);
});

// Gallery item
<Row>
  {gallery.map((src, ind) => (
    <Row xl={24}>
      <img
        onClick={() => setSpotlightInd(ind)}
        className="thumbnails"
        style={ind === spotlightInd ? { border: '2px solid #6AE9B2', boxShadow: '1px 1px 1px #1e2222' } : {}}
        alt="Doodle Gallery"
        src={src}
      />
    </Row>
  ))}
</Row>

// Mui datetime picker
<ThemeProvider theme={theme}>
  <DateTimePicker
    style={{ display: 'none' }}
    inputVariant="standard"
    label="Play Later"
    minutesStep={5}
    open={timeVisible}
    value={time.start}
    onError={console.log}
    disablePast
    format="ddd MMM Do, hh:mm a"
    onAccept={value => setTime({ start: value, time: activeEvent.time, changed: true })}
    onClose={() => setTimeVisible(false)}
    onChange={value => setTime({ start: value })}
  />
</ThemeProvider>
<Button onClick={() => setTimeVisible(true)}>Schedule</Button>
*/
