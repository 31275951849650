import React, { useState, useEffect, useMemo } from 'react';

// Functional Libraries
import day from 'dayjs';

// Contexts
import { useAuth } from '../context/auth';

// Constants
import { catchMsg } from '../constants';

// Components
import Doodle from '../components/doodle';
import { RandWho, NameList, UltimateQuestion } from '../components/toolbox.js';
import { Layout, Typography, Card, Row, Col, Tooltip, Button, Drawer, Modal, Input, message } from 'antd';

// Images/Icon
import { ClockCircleOutlined, TeamOutlined, CopyOutlined } from '@ant-design/icons';

// Styesheets
import './Less/activities.less';

// Sub components
const { Content } = Layout;
const { Title, Text } = Typography;

const tools = [
  {
    title: "Arm's Reach",
    about:
      "Arm's Reach is a fun way to have your team learn more about one another. It's a quick and easy show and tell game that takes no preparation! Participants will find an item within their arm's reach that has the most meaning to them. They then go around the (virtual) circle and tell a story behind the item, how they got it, and what it means to them.",
    people: '2+',
    time: '2 min/person',
    icon: 'https://afino-app.s3.amazonaws.com/activities/uid_3_icon.svg',
    icon_color: '#FFDFCE',
    step1: {
      title: 'Grab and Item',
      content: 'Instruct everybody to grab a nearby object with some meaning to them.',
    },
    step2: {
      title: 'Pick an order',
      content: 'Pick yourself or use one of the tools below to help out!',
    },
    step3: {
      title: 'Your turn',
      content: 'When it’s your turn, tell a story behind the item, what it means to you, and how you got it.',
    },
  },
  {
    title: 'Ultimate Questions',
    about:
      "This game is dead simple. We've curated some sorcerer level questions. Have fun with your team chatting about your answers. This is a great way to start a meeting, get to know new coworkers or just disconnect from work for a short chat with peers.",
    people: '2+',
    time: '2 min/person',
    icon: 'https://afino-app.s3.amazonaws.com/activities/uid_0_icon.svg',
    icon_color: '#EBE5FD',
    step1: {
      title: 'The Question',
      content: <UltimateQuestion white={true} />,
    },
    step2: {
      title: 'Share',
      content: "Tell everyone the question and give a moment to think about answers",
    },
    step3: {
      title: 'Answer',
      content: 'Take turns answering the question and explaining why you answered that way!',
    },
  },
  {
    title: 'Bucket List',
    about:
      'Bucket list is the perfect way for your team to get to know one another on a much deeper level. In this activity, participants will share where they would like to travel, what they’d like to try, and who they’d like to meet in their lifetime. Naturally, everyone participating will gain a much better understanding of each other.',
    people: '2+',
    time: '1 min/person',
    icon: 'https://afino-app.s3.amazonaws.com/activities/uid_17_icon.svg',
    icon_color: '#FFF8D8',
    step1: {
      title: 'Thinking...',
      content: 'Tell participants to think about where they would like to travel, what new thing they’d like to try, and who they’d like to meet.',
    },
    step2: {
      title: 'Pick an order',
      content: 'Pick yourself or use one of the tools below to help out!',
    },
    step3: {
      title: 'Share',
      content: 'When it’s your turn, share your new bucket list with your team!',
    },
  },
  {
    title: 'Shark Tank Jumble',
    about:
      "This is a great way to get your team's creative juices flowing! Participants will each come up with their own business idea and pitch it to the group. In the end, players will work together come up with a new, combined business idea that incorporates each individual's.",
    people: '3+',
    time: '3 min/person',
    icon: 'https://afino-app.s3.amazonaws.com/activities/uid_4_icon.svg',
    icon_color: '#E5FAFF',
    step1: {
      title: 'Thinking...',
      content: 'Identify the industry your team works in and give everybody a minute to come up with a unique business idea that relates to it.',
    },
    step2: {
      title: 'Pitch',
      content: 'Give each participant 30 seconds to a minute to pitch their ideas',
    },
    step3: {
      title: 'Combine',
      content: 'Work together as a group come up with a product or service that incorporates everybody’s ideas!',
    },
  },
  {
    title: 'Hidden Talents',
    about:
      'Everybody’s got a hidden talent! Give your team a chance to show their special abilities off. This activity is simple: go around the (virtual) room and get each participant to either tell their special talent, or even better, show their special talent. It’s a great opportunity to learn more about one another.',
    people: '3+',
    time: '3 min/person',
    icon: 'https://afino-app.s3.amazonaws.com/activities/uid_21_icon.svg',
    icon_color: '#E3FDEF',
    step1: {
      title: 'Thinking...',
      content: 'Allow participants some time to think of their hidden talent.',
    },
    step2: {
      title: 'Pick an order',
      content: 'Pick yourself or use one of the tools below to help out!',
    },
    step3: {
      title: 'Show Off',
      content: 'When it’s your turn, tell everyone what your hidden talent is, or show it if you can!',
    },
  },
];

const Activities = ({ categories, setComingUp }) => {
  const { api } = useAuth();
  const [activePic, setActivePic] = useState({});
  const [activeVote, setActiveVote] = useState({});
  const [activeTool, setActiveTool] = useState({});
  const [newEvent, setNewEvent] = useState({});
  const [loading, setLoading] = useState({});

  const [pics, voting] = useMemo(() => {
    const picInd = categories.findIndex(el => el.title === 'A Thousand Words');
    const voteInd = categories.findIndex(el => el.title === 'Share and Vote');

    let picEvents = [{ title: "Couldn't find", about: 'Sorry we had trouble accessing these events' }];
    let voteEvents = [{ title: "Couldn't find", about: 'Sorry we had trouble accessing these events' }];
    if (picInd > -1) {
      picEvents = categories[picInd].events;
    }
    if (voteInd > -1) {
      voteEvents = categories[voteInd].events;
    }
    return [picEvents, voteEvents];
  }, [categories]);

  useEffect(() => {
    // Randomly select a pic and vote event
    setActivePic(pics[Math.floor(Math.random() * pics.length)]);
    setActiveVote(voting[Math.floor(Math.random() * voting.length)]);
  }, [categories]);

  const shufflePic = () => {
    const nextInd = (pics.findIndex(el => el.uid === activePic.uid) + 1) % pics.length;
    setActivePic(pics[nextInd]);
  };

  const shuffleVote = () => {
    if (voting.length < 2) message.info({ content: "We'll be adding more of these events shortly", key: "noVote"}) 
    const nextInd = (voting.findIndex(el => el.uid === activeVote.uid) + 1) % voting.length;
    setActiveVote(voting[nextInd]);
  };

  const hostEvent = activeEvent => {
    if (!activeEvent.uid) {
      message.info('Sorry we could add this event');
      return;
    }
    activeEvent.category === 'Vote and Share' ? setLoading({ vote: true }) : setLoading({ pic: true });
    api
      .post('makeEvent', {
        title: activeEvent.title,
        uid: activeEvent.uid,
        start: Math.round(Date.now() / 10000),
        end: Math.round((Date.now() + activePic.time * 60000) / 10000),
        gameOptions: {},
      })
      .then(res => {
        if (res.data.message === 'Success') {
          message.success('The event has been added');
          setNewEvent({ ...activeEvent, ...res.data });
        } else {
          message.warning(res.data.message);
        }
        setLoading({});
      })
      .catch(err => {
        message.error(catchMsg(err));
        setLoading({});
      });
  };

  const pushEvent = () => {
    setComingUp({ do: 'add', activity: newEvent });
    setNewEvent({});
  };
  return (
    <div style={{ minHeight: 'calc(100vh - 42px)', paddingBottom: 60 }} className="pageMargin">
      <Row style={{ paddingTop: 20 }}>
        <Col xs={24} sm={20}>
          <Title level={3} style={{ marginBottom: 0 }}>
            Doodle
          </Title>
          <Text>Spark friendly competition with this office duel! It's just like pictionary, but VIRTUAL! Play this game with your team during your next break... To the drawing board!</Text>
        </Col>
        <Col xs={0} sm={4} style={{ textAlign: 'right' }}>
          <img src="https://afino-app.s3.amazonaws.com/activities/uid_1_icon.svg" style={{ width: 60 }} />
        </Col>
      </Row>
      <Content>
        <Row className="acitivities-parent-card">
          <Doodle setComingUp={setComingUp} />
        </Row>
        <Row justify="space-between" gutter={[16, 0]} style={{ marginTop: 30 }}>
          <Col xs={24} lg={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={18} style={{ paddingLeft: 10 }}>
                <Title level={3}>A Thousand Words</Title>
                <Text>Play this over the course of the week to get to know your team better. Shuffle for a new option!</Text>
              </Col>
              <Col xs={0} sm={6} style={{ textAlign: 'right' }}>
                <img src="https://afino-app.s3.amazonaws.com/activities/camera.svg" style={{ width: 80, marginRight: 5 }} />
              </Col>
            </Row>
            <div className="async-container">
              <Row>
                <iframe
                  allowFullScreen
                  src="https://youtube.com/embed/5V-UaoINVSk?rel=0"
                  title="Instruction Video"
                  frameBorder="0"
                  className="event-video"
                />
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Title level={4}>
                  {activePic.title} <img alt="event icon" src={activePic.icon} width={30} />
                </Title>
              </Row>
              <Row>
                <Text>{activePic.about}</Text>
              </Row>
              <Row style={{ marginTop: 10 }} gutter={[16, 16]}>
                <Col>
                  <Button type="primary" loading={loading.pic} onClick={() => hostEvent(activePic)}>
                    Play
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" ghost onClick={() => shufflePic()}>
                    Shuffle
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={18} style={{ paddingLeft: 10 }}>
                <Title level={3}>Share and Vote</Title>
                <Text>Play this throughout a day or use it as a tool for your next town hall. Shuffle for a new option!</Text>
              </Col>
              <Col xs={0} sm={6} style={{ textAlign: 'right' }}>
                <img src="https://afino-app.s3.amazonaws.com/activities/vote.svg" style={{ width: 80, marginRight: 5 }} />
              </Col>
            </Row>
            <div className="async-container">
              <Row>
                <iframe
                  allowFullScreen
                  src="https://youtube.com/embed/oHX7wuPya7A?rel=0"
                  title="Instruction Video"
                  frameBorder="0"
                  className="event-video"
                />
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Title level={4}>
                  {activeVote.title} <img alt="event" src={activeVote.icon} width={30} />
                </Title>
              </Row>
              <Row>
                <Text>{activeVote.about}</Text>
              </Row>
              <Row style={{ marginTop: 10 }} gutter={[16, 16]}>
                <Col>
                  <Button type="primary" loading={loading.vote} onClick={() => hostEvent(activeVote)}>
                    Play Now
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" ghost onClick={() => shuffleVote()}>
                    Shuffle
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Title level={3} style={{ marginTop: 30, marginBottom: 0 }}>
          Tools
        </Title>
        <Text>We’ve made some tools for managers! This is the ultimate kit to supercharge your virtual meetings.</Text>
        <div style={{ paddingRight: 25, marginTop: 10 }}>
          <Row className="engage-cardWrap" gutter={[16, 16]}>
            {tools.map(tool => (
              <Col xs={20} sm={24} md={12} lg={8} xl={8} xxl={6}>
                <Card hoverable className="activity-card" bodyStyle={{ padding: '10px 16px' }} key={tool.title} onClick={() => setActiveTool(tool)}>
                  <Row align="top">
                    <Col xs={24} sm={18}>
                      <Title style={{ color: '#ffffff' }} level={5}>
                        {tool.title}
                      </Title>
                      <Tooltip title="Approximate Duration">
                        <Text style={{ color: '#6ae9b2' }}>
                          <ClockCircleOutlined className="info-icon" />
                          {tool.time}
                        </Text>
                      </Tooltip>
                      <Tooltip title="Number of People">
                        <Text style={{ marginLeft: 6, color: '#6ae9b2' }}>
                          <TeamOutlined className="info-icon" />
                          {tool.people}
                        </Text>
                      </Tooltip>
                    </Col>
                    <Col xs={0} sm={6}>
                      <div className="tool-icon" style={{ backgroundColor: tool.icon_color }}>
                        <img alt="event" src={tool.icon} style={{ width: '90%' }} />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Content>

      {/* Modal for when event is added */}
      <Modal
        centered
        title={
          <Title level={4}>
            {newEvent.title} <img alt="Event Icon" src={newEvent.icon} style={{ width: 30 }} />
          </Title>
        }
        onCancel={pushEvent}
        visible={newEvent.title}
        footer={
          newEvent.end ? <Text type="secondary">Link Expires: {day.unix((newEvent.end + 259200) * 10).format('ddd MMM Do, hh:mm a z')}</Text> : null
        }
      >
        <Title level={5}>Send to Team</Title>
        <Input.Search
          placeholder="Event Link Appears Here"
          enterButton="Copy"
          value={window.location.origin + '/event?id=' + newEvent._id}
          suffix={<CopyOutlined />}
          onSearch={value =>
            navigator.clipboard.writeText(value).then(() => {
              message.success({ content: 'Copied!', key: 'Copy' });
            })
          }
          style={{ width: '100%', marginTop: 10, display: 'inline-block' }}
        />
        <br />
        <Title style={{ marginTop: 20 }} level={5}>
          Add to Calendar
        </Title>
        <Button
          style={{ marginTop: 10 }}
          type="primary"
          ghost
          target="_blank"
          rel="noopener noreferrer"
          href={`https://calendar.google.com/calendar/r/eventedit?text=${newEvent.title}&details=${newEvent.description}&location=${
            window.location.origin + '/event?id=' + newEvent._id
          }&dates=${day.unix(newEvent.start * 10).format('YYYYMMDDTHHmmSS')}/${day
            .unix(newEvent.end * 10)
            .format('YYYYMMDDTHHmmSS')}&add=team@afino.io`}
        >
          Google
        </Button>
        <Button
          style={{ marginTop: 10, marginLeft: 10 }}
          type="primary"
          ghost
          target="_blank"
          rel="noopener noreferrer"
          href={`https://outlook.office.com/calendar/0/deeplink/compose?&subject=${newEvent.title}&body=${newEvent.description}&location=${
            window.location.origin + '/event?id=' + newEvent._id
          }&startdt=${day.unix(newEvent.start * 10).format('YYYY-MM-DDTHH:mm:SS')}&enddt=${day
            .unix(newEvent.end * 10)
            .format('YYYY-MM-DDTHH:mm:SS')}&popoutv2=1&to=team@afino.io`}
        >
          Outlook
        </Button>
      </Modal>

      {/* This is the display for the active tool */}
      <Drawer
        title={null}
        placement="right"
        visible={activeTool.title}
        onClose={() => setActiveTool({})}
        width={window.innerWidth < 992 ? '100%' : 'calc(100% - 210px)'}
        bodyStyle={{ backgroundColor: '#f5f5f5' }}
      >
        <Row>
          <Col xs={20}>
            <Title level={4}>{activeTool.title}</Title>
            <Text>{activeTool.about}</Text>
          </Col>
          <Col xs={0} sm={4} style={{ textAlign: 'right', paddingRight: 70 }}>
            <img alt="event" src={activeTool.icon} width={80} style={{ marginLeft: 5 }} />
          </Col>
        </Row>
        <div className="tool-container">
          <Title level={5}>Overview</Title>
          <Text>{activeTool.description}</Text>
          {activeTool.title && (
            <Row justify="space-between" gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <div className="tool-step">
                  <Title level={4} style={{ color: 'white' }}>
                    <div className="tool-bubble">1</div>
                    {activeTool.step1.title}
                  </Title>
                  <Text style={{ color: 'white' }}>{activeTool.step1.content}</Text>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <div className="tool-step">
                  <Title level={4} style={{ color: 'white' }}>
                    <div className="tool-bubble">2</div>
                    {activeTool.step2.title}
                  </Title>
                  <Text style={{ color: 'white' }}>{activeTool.step2.content}</Text>
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="tool-step">
                  <Title level={4} style={{ color: 'white' }}>
                    <div className="tool-bubble">3</div>
                    {activeTool.step3.title}
                  </Title>
                  <Text style={{ color: 'white' }}>{activeTool.step3.content}</Text>
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={10}>
              <div className="tool-generator">
                <Title level={4} style={{ color: 'white' }}>
                  Who Goes Fist?
                </Title>
                <Text style={{ color: 'white' }}></Text>
                <RandWho white={true} />
              </div>
            </Col>
            <Col xs={24} sm={14}>
              <div className="tool-generator">
                <Title level={4} style={{ color: 'white' }}>
                  Generate Order
                </Title>
                <Text style={{ color: 'white' }}></Text>
                <NameList white={true} />
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
};

export default React.memo(Activities);

/*
useEffect(() => {
  if (suggested.length > 0) {
    setActiveEvent(suggested[2]);
  } else if (!activeEvent.title && categories.length > 0) {
    if (categories[0].events.length > 0) {
      setActiveEvent(categories[0].events[0]);
    }
  }

  const selected = new URLSearchParams(location.search).get('uid');
  if (selected) {
    const activitySelected = getEvent(selected);
    if (activitySelected.title) {
      setActiveTool(activitySelected);
    }
  }
}, [categories]);
*/
