export const APIbaseURL = process.env.NODE_ENV === 'development' ? "/api/" : "https://api.afino.io/api/";
export const SocketURL = process.env.NODE_ENV === 'development' ? "/" : "https://api.afino.io/";
export const RealtimeURL = process.env.NODE_ENV === 'development' ? "/realtime" : "https://api.afino.io/realtime";

export const Stripe_API_Key = process.env.NODE_ENV === 'development' ? 'pk_test_51HWlZLIaOuYdhF5xyZouVXFugRjhF73lW7a0ZbB2YDamGhkgc6Gq6L0HONL5p9uCHevuOpiX337kdtZaVEAU9fka00V9WRSBhs' : 'pk_live_51HWlZLIaOuYdhF5x5t7x1TWuLWgvkcoQrC8l5Td3mw83XSFH2qm4k5nKMZFGfZtWjpF00uOVkbyYXAZcnVY1i9S1005ENdPYUS'

export function catchMsg(error) {
  const code = typeof error.response !== 'undefined' ? error.response.status : null;
  if (code === 404) return ({content: "Sorry, we couldn't find what you're looking for.", key: "error"})
  if (code === 401) {
    setTimeout(() => {
      window.location = "/signin"
    }, 2000);
    return ({content: "Not signed in", key: "error"})
  }
  if (code === 422) return ({content: "Invalid or missing arguments", key: "error"})
  if (code === 500) {
    return ({content: "We're working on fixing an issue. Please let us know if the error persists.", key: "error"})
  }
  else return ({content: "We're working on fixing an issue. Please let us know if the error persists.", key: "error"})
}

export const emailRules = [
  {
    required: true,
    message: 'Please input an email!',
  },
  {
    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: "Enter a valid email",
  },
]

export const passwordRules = [
  {
    required: true,
    message: 'Please enter a password',
  },
  {
    pattern: /[!$%^&*()_|~@#`{}[\]:";'<>?,.\\/]/,
    message: 'Password must include at least 1 symbol'
  },
  {
    pattern: /[A-Z]/,
    message: 'Password must include at least 1 uppercase letter'
  },
  {
    pattern: /[a-z]/,
    message: 'Password must include at least 1 lowercase letter'
  },
  {
    pattern: /[0-9]/,
    message: 'Password must include at least 1 number'
  },
  {
    min: 8,
    message: 'Password must be 8+ characters',
  },
  {
    pattern: /^\S*$/,
    message: 'Password must not have spaces or tabs'
  }
]

export const whoGoesFirst = ['Youngest person', 'Oldest Person', 'Earliest Birthday', 'Latest Birthday', 'Tallest', 'Shortest', 'Longest Name', 'Shortest Name', 'Most Seniority', 'Least Seniority', 'Last to Join call', 'First to join call', ];

export const ultimateQuestionList = [
  "What is one thing you did today that you are proud of?",
	"What is one thing about your home culture you can’t relate to?",
	"What is one stereotype you are grouped into that you can’t stand?",
	"If you didn't have to sleep, what would you do with your spare time?",
	"What hobby would you get into if time and money weren't an issue?",
	"What would your perfect room look like?",
	"What fictional place would you like to go to?",
	"What job would you be terrible at?",
	"When was the last time you climbed a tree?",
	"If you could turn any activity into a Olympic sport, what would you have a good chance at winning a medal for?",
	"What skill would you like to master?",
	"What is the most annoying habbit that other people have?", 
	"What would be the most amazing adventure to go on?", 
	"If you had unlimited funds to build a house that you would live in for the rest of your life, what would the finished house be like?",
	"What’s your favorite drink?",
	"What state or country do you never want to go back to?",
	"What songs have you completely memorized?",
	"What game or movie universe would you most like to live in?",
	"What do you consider to be your best find?",
	"Are you usually early or late?",
	"What pets did you have while you were growing up?",
	"When people come to you for help, what do they usually want help with?",
	"What takes up too much of your time?",
	"What do you wish you knew more about?",
	"What are some small things that make your day better?",
	"Who’s your go to band or artist when you can’t decide on something to listen to?",
	"What shows are you into?",
	"What TV channel doesn’t exist but really should?",
	"Who has impressed you most with what they’ve accomplished?",
	"What age do you wish you could permanently be?",
	"What TV show or movie do you refuse to watch?",
	"What would be your ideal way to spend the weekend?",
	"What is something that is considered a luxury, but you don’t think you could live without?",
	"What’s your claim to fame?",
	"What’s something you like to do the old-fashioned way?",
	"What’s your favorite genre of book or movie?",
	"How often do you people watch?",
	"What have you only recently formed an opinion about?",
	"What’s the best single day on the calendar?",
	"What are you interested in that most people haven’t heard of?",
	"How do you relax after a hard day of work?",
	"What was the best book or series that you’ve ever read?",
	"What’s the farthest you’ve ever been from home?",
	"What is the most heartwarming thing you’ve ever seen?",
	"What is the most annoying question that people ask you?",
	"What could you give a 40-minute presentation on with absolutely no preparation?",
	"If you were dictator of a small island nation, what crazy dictator stuff would you do?",
	"What is something you think everyone should do at least once in their lives?",
	"Would you rather go hang gliding or whitewater rafting?",
	"What’s your dream car?",
	"What’s worth spending more on to get the best?",
	"What is something that a ton of people are obsessed with but you just don’t get the point of?",
	"What are you most looking forward to in the next 10 years?",
	"Where is the most interesting place you’ve been?",
	"What’s something you’ve been meaning to try but just haven’t gotten around to it?",
	"What’s the best thing that happened to you last week?",
	"What piece of entertainment do you wish you could erase from your mind so that you could experience for the first time again?",
	"If all jobs had the same pay and hours, what job would you like to have?",
	"What amazing thing did you do that no one was around to see?",
	"How different was your life one year ago?",
	"What’s the best way to start the day?",
	"What quirks do you have?",
	"What would you rate 10 / 10?",
	"What fad or trend do you hope comes back?",
	"What’s the most interesting piece of art you’ve seen?",
	"What kind of art do you enjoy most?",
	"What do you hope never changes?",
	"What city would you most like to live in?",
	"What movie title best describes your life?",
	"Why did you decide to do the work you are doing now?",
	"What’s the best way a person can spend their time?",
	"If you suddenly became a master at woodworking, what would you make?",
	"Where is the most relaxing place you’ve ever been?",
	"What is the luckiest thing that has happened to you?",
	"Where would you rather be from?",
	"What are some things you’ve had to unlearn?",
	"What are you looking forward to in the coming months?",
	"What website do you visit most often?",
	"What one thing do you really want but can’t afford?",
	"Where do you usually go when you when you have time off?",
	"Where would you spend all your time if you could?",
	"What is special about the place you grew up?",
	"What age do you want to live to?",
	"What are you most likely to become famous for?",
	"What are you absolutely determined to do?",
	"What is the most impressive thing you know how to do?",
	"What do you wish you knew more about?",
	"What question would you most like to know the answer to?",
	"What was the best compliment you’ve received?",
	"As the only human left on Earth, what would you do?",
	"Who inspires you to be better?",
	"What do you want your epitaph to be?",
	"What did you think you would grow out of but haven’t?",
	"In what situation or place would you feel the most out of place in?",
	"What’s the dumbest thing you’ve done that actually turned out pretty well?",
	"They say that everyone has a book in them. What would your book be about?",
	"What is something you will NEVER do again?",
	"What do you spend the most time thinking about?",
	"What are some of the events in your life that made you who you are?",
	"What do you wish your brain was better at doing?",
	"There are two types of people in this world. What are the two types?",
	"What is the strangest thing you have come across?",
	"What is something you are certain you’ll never experience?",
	"What dumb accomplishment are you most proud of?",
	"If you could make one rule that everyone had to follow, what rule would you make?",
	"What are you addicted to?",
	"What stereotype do you completely live up to?",
	"What is something you can never seem to finish?",
	"As you get older, what are you becoming more and more afraid of?",
	"What is one of your favorite smells?",
	"What would be the scariest monster you could imagine?",
	"What song or artist do you like but rarely admit to liking?",
	"What would you do if you knew you were going to die in one hour?",
	"What book impacted you the most?",
	"If you had to change your name, what would you change it to?",
	"If life is a game, like some people say, what are some of the rules?",
	"Who is / was your most interesting friend?",
	"Have you ever given to any charities?",
	"What is something that your friends would consider “so you”?",
	"What risks are worth taking?",
	"What can you not get right, no matter how many times you try?",
	"If you could convince everyone in the world to do one thing at one point in time, what would that thing be?",
	"What do you take for granted?",
	"What would be some of the most annoying things about having yourself as a roommate?",
	"What’s something you are self-conscious about?",
	"What personality trait do you value most and which do you dislike the most?",
	"What small gesture from a stranger made a big impact on you?",
	"What gets you fired up?",
	"What challenging thing are you working through these days?",
	"What irrational fear do you have?",
	"What’s the best and worst piece of advice you’ve ever received?",
	"If you had a clock that would countdown to any one event of your choosing, what event would you want it to countdown to?",
	"What makes a good life?",
	"What do you strongly suspect but have no proof of?",
	"What’s the last adventure you went on?",
	"When do you feel truly “alive”?",
	"What was the most memorable gift you’ve received?",
	"What chapters would you separate your autobiography into?",
	"What do you like most about your family?",
	"What do you hope your last words will be?",
	"What stat for your life would you most like to see?",
	"What are three of the most significant numbers in your life?",
	"What could you do with two million dollars to impact the most amount of people?",
	"If you were put into solitary confinement for six months, what would you do to stay sane?",
	"What’s something horrible that everyone should try at least once?",
	"What fact are your resigned to?",
	"Have you ever saved someone’s life?",
	"What were some of the turning points in your life?",
	"What would a mirror opposite of yourself be like?",
	"What are you really good at, but kind of embarrassed that you are good at it?",
	"What are three interesting facts about you?",
	"Which of your scars has the best story behind it?",
	"What’s the title of the current chapter of your life?",
	"What’s the hardest lesson you’ve learned?",
	"What mistake do you keep making again and again?",
	"What do people think is weird about you?",
	"When people look at you, what do you think they see / think?",
	"What have you created that you are most proud of?",
	"If you could make a 20 second phone call to yourself at any point in your life present or future, when would you call and what would you say?",
	"What annoys you most about the in-groups you are a part of?",
	"What do you have doubts about?",
	"What do you want to be remembered for?",
	"What are some of your personal “rules” that you never break?",
	"What do you regret not doing or starting when you were younger?",
	"If you could have a never-ending candle that smelled like anything you wanted, what fragrance would you want it to be?",
	"What’s the best thing about you?",
	"What bends your mind every time you think about it?",
	"What’s the best thing you got from your parents?",
	"What’s one responsibility you really wish you didn’t have?",
	"What is the “holy grail” of your life?",
	"If your childhood had a smell, what would it be?",
	"What are the top three things you want to accomplish before you die? How close are you to accomplishing them?",
	"What do you wish you could tell yourself 10 years ago? What do you think you’ll want to tell your current self 10 years from now?",
	"In your group of friends, what role do you play?",
	"Among your friends or family, what are you famous for?",
	"What is the biggest lesson you never learned?",
	"What’s the most immature thing that you do?",
	"If your life was a book, what would its title be?",
	"What’s the best and worst thing about getting older?",
	"What’s something that happened or something that someone said that changed how you view the world?",
	"What are you most likely very wrong about?",
	"If you had a personal flag, what would be on it?",
	"What lifestyle change have you been meaning to make for a while now?",
	"What would be your spirit animal?",
	"What incredibly strong opinion or belief do you have that is completely unimportant in the grand scheme of things?",
	"What chance encounter changed your life forever?",
	"If you could have a video of any one event in your life, what event would you choose?",
	"If you were forced to relive one 10-minute block of your life again and again for all eternity, what 10 minutes of your life would you choose?",
	"How do you hope you’ll change as a person in the future?",
	"What keeps you up at night?",
	"What’s the most surprising self-realization you’ve had?",
	"What’s the most illegal thing you’ve done?",
	"What lie do you tell most often?",
	"What do you regret not doing?",
	"What gives your life meaning?",
	"What do you most often look down on people for? What do you think other people look down on you for?",
	"What bridges do you not regret burning?",
	"What are you most insecure about?",
	"How do you get in the way of your own success?",
	"What’s one thing you did that you really wish you could go back and undo?",
	"What are you afraid people see when they look at you?",
	"When you are old, what do you think children will ask you to tell stories about?",
	"If you could switch two movie characters, what switch would lead to the most inappropriate movies?",
	"What animal would be cutest if scaled down to the size of a cat?",
	"What inanimate object would be the most annoying if it played loud upbeat music while being used?",
	"When did something start out badly for you but in the end, it was great?",
	"What weird food combinations do you really enjoy?",
	"How would your country change if everyone, regardless of age, could vote?",
	"What are some red flags to watch out for in daily life?",
	"If your job gave you a surprise three day paid break to rest and recuperate, what would you do with those three days?",
	"Where do you get your news?",
	"What movie can you watch over and over without ever getting tired of?",
	"What’s wrong but sounds right?",
	"What’s the most epic way you’ve seen someone quit or be fired?",
	"If you couldn’t be convicted of any one type of crime, what criminal charge would you like to be immune to?",
	"What social stigma does society need to get over?",
	"What’s the most creative use of emojis you’ve ever seen?",
	"What’s something that will always be in fashion, no matter how much time passes?",
	"What actors or actresses play the same character in almost every movie or show they do?",
	"In the past people were buried with the items they would need in the afterlife, what would you want buried with you so you could use it in the afterlife?",
	"What’s the best / worst practical joke that you’ve played on someone or that was played on you?",
	"Who do you go out of your way to be nice to?",
	"Where do you get most of the decorations for your home?",
	"What food have you never eaten but would really like to try?",
	"What food is delicious but a pain to eat?",
	"Who was your craziest / most interesting teacher?",
	"What “old person” things do you do?",
	"What was the last photo you took?",
	"What is the most amazing slow motion video you’ve seen?",
	"Where are some unusual places you’ve been?",
	"Which celebrity do you think is the most down to earth?",
	"What would be the worst thing to hear as you are going under anesthesia before heart surgery?",
	"What’s the spiciest thing you’ve ever eaten?",
	"What’s the most expensive thing you’ve broken?",
	"What obstacles would be included in the World’s most amazing obstacle course?",
	"What makes you roll your eyes every time you hear it?",
	"What do you think you are much better at than you actually are?",
	"Should kidneys be able to be bought and sold?",
	"When was the last time you got to tell someone “I told you so.”?",
	"What would a world populated by clones of you be like?",
	"What riddles do you know?",
	"What’s your cure for hiccups?",
	"What invention doesn’t get a lot of love, but has greatly improved the world?",
	"What’s something you really resent paying for?",
	"Do you think that aliens exist?",
	"What are you currently worried about?",
	"What’s the most interesting building you’ve ever seen or been in?",
	"What mythical creature do you wish actually existed?",
	"What are your most important rules when going on a date?",
	"How do you judge a person?",
	"If someone narrated your life, who would you want to be the narrator?",
	"What was the most unsettling film you’ve seen?",
	"What unethical experiment would have the biggest positive impact on society as a whole?",
	"When was the last time you were snooping, and found something you wish you hadn’t?",
	"Which celebrity or band has the worst fan base?",
	"What are you interested in that most people aren’t?",
	"If you were given a PhD degree, but had no more knowledge of the subject of the degree besides what you have now, what degree would you want to be given to you?",
	"What smartphone feature would you actually be excited for a company to implement?",
	"What’s something people don’t worry about but really should?",
	"What movie quotes do you use on a regular basis?",
	"Do you think that children born today will have better or worse lives than their parents?",
	"What’s the funniest joke you know by heart?",
	"When was the last time you felt you had a new lease on life?"
]

export const tagInfo = {
  Happiness: 'Aims to improve excitement about the type of work and work environment',
  'Learning Opportunities': 'Enhances the ability to develop skills and work towards career goals',
  Recognition: 'Promotes employee sense of recognition from managers and peers',
  'Coworker Relationships': 'Increases the level of trust, support, and camaraderie with peers',
  'Manager Relationship': 'Boosts the level of trust, support, and communication with the manager',
  'Health and Wellness': 'Improves mental and physical health, work-life balance, and stress level',
};

export const metricFacts = {
  Happiness: {
    icon: '😁',
    step: [
      'Happy employees are on average 12 percent more productive than their less-happy counterparts',
      'Happy employees are more likely to drive innovation and take risks',
    ],
    description:
      'Happier employees work harder, are better at collaborating in teams, and tend to be more productive than their unhappier counterparts.',
    intro: 'Some key insights on the importance of happy employees include: ',
    cite: [
      '[1] (Craig, 3 Reasons Why Employee Recognition Will Always Matter 2017)',
      '[2] (Written by Gerald AinomugishaGerald is a freelance writer with a pen that is keen for entrepreneurship, Ainomugisha, &amp; Gerald is a freelance writer with a pen that is keen for entrepreneurship, 5 Great Reasons Why Happiness Increases Productivity 2020)',
    ]
  },
  'Learning Opportunities': {
    icon: '📚',
    step: [
      'Teams engaged in employee development saw sales increase and profits double compared to teams that didn’t engage at all.',
      '94% of employees say they would stay at a company longer if it invested in their career development.',
    ],
    description:
      'Learning and development initiatives provide opportunities for your team to expand their skillsets, leading to increased performance, improved satisfaction, and reduced turnover.',
    intro: 'Some key notes from research include:',
    cite: ["[1] (Employee Training and Development: The Benefits and Why it's Important 2020)"]
  },
  Recognition: {
    icon: '👏',
    step: [
      '41% of companies that use peer to peer recognition have seen positive increases in customer satisfaction',
      '66% of workers are likely to leave their job if they feel unappreciated',
    ],
    description:
      'Recognizing your team members for the work they accomplish leads to happier, more motivated, and better-performing employees that are more likely to stay with your company.',
    intro: 'Some key insights on the importance of recognition include:',
    cite: [
      '[1] (Workplace, Employee Recognition: What It Is, Why It Matters, and How to Use it Effectively)',
      "[2] (THANKS, BUT NO THANKS: Survey Reveals Strangest Forms of Workplace Recognition; Research Also Finds Two in Three Employees Would Leave Their Job If They Didn't Feel Appreciated)"
    ]
  },
  'Coworker Relationships': {
    icon: '🤝',
    step: [
      'Over 7 times more likely to be engaged in their jobs',
      'More confident voicing opinions, brainstorming, and going along with new ideas',
    ],
    description:
      'Strong relationships between peers on your team is important to keeping people happy, improving teamwork, retaining employees, and maximizing productivity.',
    intro: "Team's with strong co-worker relationships are: ",
    cite: [
      '[1] (the Mind Tools Content Team By the Mind Tools Content Team, Team, wrote, wrote, &amp; wrote, Building Good Work Relationships: Making Work Enjoyable and Productive)'
    ]
  },
  'Manager Relationship': {
    icon: '👔',
    step: [
      'Able to retain key talent and reduce costs associated with recruiting, hiring, and training new workers',
      'Less likely to experience workplace conflicts and often see higher levels of productivity',
    ],
    description:
      'A strong employee-manager relationship builds morale, maintains open communication in the workplace, and encourages performance and productivity.',
    intro: 'Teams with strong manager relationships are:',
    cite: ['[1] (Resource, The Importance of a Strong Employer/Employee Relationship: Blogs: Careers at Nesco Resource)']
  },
  'Health and Wellness': {
    icon: '🧘‍♀️',
    step: [
      'Employees actively engaged in health and wellness activities outperform their peers by 25%+',
      '89% of employees at companies with wellness programs are more likely to recommend their company to other job seekers',
    ],
    description: 'Helping to keep your team healthy has a strong impact on workplace culture, productivity, and ultimately, your bottom line.',
    intro: 'Some key insights on employee health and wellness include:',
    cite: ['[1] (Sep et al., 12 Reasons Wellness at Work Programs Are Important 2020)']
  }
}
