import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Functional Libraries
import day from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import createG2 from 'g2-react';

// Contexts
import { useAuth } from '../context/auth';

// Constants
import { catchMsg } from '../constants';

// Components
import { Layout, Typography, Card, Modal, Button, Row, Col, List, Input, message } from 'antd';
import { ChartCard } from 'ant-design-pro/lib/Charts';

// Images/Icon
import {
  StarTwoTone,
  ContactsTwoTone,
  CopyOutlined,
  CalendarTwoTone,
  HourglassTwoTone,
  CrownTwoTone,
} from '@ant-design/icons';

// Styesheets
import 'ant-design-pro/dist/ant-design-pro.css';
import './Less/dashboard.less';

// Sub components
const { Header, Content } = Layout;
const { Title, Text } = Typography;

// Date formatting
day.extend(advancedFormat);
day.extend(utc);
day.extend(timezone);

const Dashboard = ({ pastEvents, comingUp, setComingUp, getEvent }) => {
  const { api, company } = useAuth();
  const [activeEvent, setActiveEvent] = useState({});
  const [loading, setLoading] = useState(false);

  // Only recalculate this when pastEvents changes, instead of every render
  const pastMetrics = useMemo(() => {
    let attendance = [...pastEvents]; 
    // This just removes
    attendance.filter((pastEvent, ind, arr) => {
      if (ind > 0 && day.unix(pastEvent.start * 10).format('M D YY') === day.unix(arr[ind - 1].start * 10).format('M D YY')) {
        return false;
      } else return true;
    });
    return {
      avgRating: (
        pastEvents.reduce(
          (accum, { rating: { 1: one, 2: two, 3: three, 4: four, 5: five } }) => accum + (one + two * 2 + three * 3 + four * 4 + five * 5),
          0
        ) / pastEvents.reduce((accum, { rating: { 1: one, 2: two, 3: three, 4: four, 5: five } }) => accum + one + two + three + four + five, 0) || 0
      ).toFixed(2),
      avgAttendance: (pastEvents.reduce((accum, { attendance }) => accum + attendance, 0) / pastEvents.length || 0).toFixed(0),
      eventTime: (pastEvents.reduce((accum, { start, end }) => end - start + accum, 0) / 360).toFixed(1),
      // Return the data for the attendance graph
      // Rolling average up to 3
      chartAttendance: pastEvents.map((pastEvent, ind) => ({
        Date: day.unix(pastEvent.start * 10).format('MMM D, YY'),
        Attendance: Math.ceil(
          ind > 1
            ? (pastEvents[ind - 2].attendance + pastEvents[ind - 1].attendance + pastEvent.attendance) / 3
            : ind > 0
            ? (pastEvents[ind - 1].attendance + pastEvent.attendance) / 2
            : pastEvent.attendance
        ),
      })),
    };
  }, [pastEvents]);

  const analyticCards = [
    {
      title: 'Average Rating',
      description: pastMetrics.avgRating + ' / 5',
      avatar: <StarTwoTone twoToneColor="#5D22A1" className="engagement-metric" />,
    },
    {
      title: 'Average Attendance',
      description: pastMetrics.avgAttendance + ' People',
      avatar: <ContactsTwoTone twoToneColor="#5D22A1" className="engagement-metric" />,
    },
    {
      title: 'Number of Events',
      description: pastEvents.length + ' Events',
      avatar: <CalendarTwoTone twoToneColor="#5D22A1" className="engagement-metric" />,
    },
    {
      title: 'Activity Hours',
      description: pastMetrics.eventTime + ' Hours',
      avatar: <HourglassTwoTone twoToneColor="#5D22A1" className="engagement-metric" />,
    },
    {
      title: 'Leaderboard',
      description: <Link to={"/leaderboard?company="+company}><Button>View</Button></Link>,
      avatar: <CrownTwoTone twoToneColor="#5D22A1" className="engagement-metric" />,
    },
  ];

  // Delete an upcoming event
  const deleteEvent = () => {
    setLoading(true);
    api
      .post('deleteEvent', {
        id: activeEvent._id,
      })
      .then(res => {
        setLoading(false);
        setActiveEvent({});
        if (res.data.message === 'Success') {
          message.success(res.data.message);
          setComingUp({ do: 'remove', id: activeEvent._id });
        } else res.warning(res.data.message);
      })
      .catch(err => {
        message.error(catchMsg(err));
        setLoading(false);
      });
  };

  const AvgAttendance = createG2(chart => {
    chart.line().position('Date*Attendance').color('#6ae9b2').shape('line').size(2);
    chart.render();
  });

  const Feedback = ({ pastEvent }) => {
    console.log("Get Feedback ", pastEvent);
    const icon = getEvent(pastEvent.uid).icon;
    return (
      <>
        {pastEvent.feedback.map(feedback => (
          <div style={{ width: '100%', paddingBottom: 8, marginLeft: 20, marginRight: 20 }}>
            <Row justify="space-between">
              <Col span={21}>
                <Text> {feedback} </Text>
              </Col>
              <Col span={1}>
                <img src={icon} width={25} />
              </Col>
            </Row>
          </div>
        ))}
      </>
    );
  };

  return (
    <div style={{ minHeight: 'calc(100vh - 42px)' }} className="pageMargin">
      <Header style={{ padding: '20px 0' }}>
        <Title level={3}>Overview</Title>
      </Header>
      <Content>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={9} xl={8} xxl={6}>
            <div className="dashboard-dashSection dashboard-metric-list">
              <Row justify="space-between" align="middle" gutter={[0, 0]} style={{ margin: '16px 20px' }}>
                <Col>
                  <Title level={4}> Engagement </Title>
                </Col>
                <Col>
                  {/*<Dropdown overlay={TimeMenu}>
                    <Button className="dashboard-metrics-filter">
                      All Time <DownOutlined />
                    </Button>
                  </Dropdown>*/}
                </Col>
              </Row>
              <List
                itemLayout="horizontal"
                dataSource={analyticCards}
                renderItem={item => (
                  <List.Item
                    extra={
                      <Text strong style={{ paddingRight: 20 }}>
                        {item.description}
                      </Text>
                    }
                  >
                    <List.Item.Meta avatar={<div>{item.avatar}</div>} title={<Text style={{ paddingRight: 5 }}>{item.title}</Text>} />
                  </List.Item>
                )}
              />
            </div>
          </Col>
          <Col xs={24} lg={15} xl={16} xxl={18}>
            {pastMetrics.chartAttendance == 0 ? (
              <div style={{ zIndex: 100, backgroundColor: '#93949B90', height: 350, position: 'absolute', width: '100%' }}>
                <Row justify="center">
                  <div style={{ backgroundColor: 'white', marginTop: 24, padding: 20 }}>
                    <Title level={4} style={{ textAlign: 'center' }}>
                      You haven't hosted an event yet.{' '}
                    </Title>
                    <Text> To get started </Text>
                    <Link to="/engage">
                      <Button type="text" style={{ padding: 0, color: '#5D22A1', fontWeight: 'bold' }}>
                        Browse built-in events
                      </Button>
                    </Link>
                    <Text type="secondary"> or </Text>
                    <Link to="/partnerships">
                      <Button type="text" style={{ padding: 0, color: '#5D22A1', fontWeight: 'bold' }}>
                        hosted experiences.
                      </Button>
                    </Link>
                  </div>
                </Row>
              </div>
            ) : null}
            <ChartCard
              title={<Title level={4}> Average Attendance </Title>}
              total={
                <Text style={{ fontSize: 12 }} type="secondary">
                  {pastMetrics.avgAttendance + ' people'}
                </Text>
              }
              contentHeight={250}
              className="dashboard-dashSection dashboard-metric-list"
              bodyStyle={{ paddingLeft: 20, paddingTop: 16 }}
            >
              <AvgAttendance height={240} forceFit={true} data={pastMetrics.chartAttendance} plotCfg={{ margin: [10, 15, 50, 60] }} />
            </ChartCard>
          </Col>
        </Row>
        <Title level={4} style={{ paddingTop: 28 }}>
          Quick Actions
        </Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <div
              className={comingUp.length > 6 ? 'dashboard-dashSection dashboard-comingUpcard-scroll' : 'dashboard-dashSection dashboard-comingUpcard'}
            >
              <Title level={4} style={{ margin: '16px 20px' }}>
                Active Events
              </Title>
              {comingUp.length === 0 && (
                <div style={{ marginLeft: 20 }}>
                  <Text type="secondary">No coming up events.</Text>
                  <Link to="/engage">
                    <Button type="text" style={{ padding: 0, color: '#5D22A1', fontWeight: 'bold' }}>
                      Browse built-in events
                    </Button>
                  </Link>
                  <Text type="secondary"> or </Text>
                  <Link to="/partnerships">
                    <Button type="text" style={{ padding: 0, color: '#5D22A1', fontWeight: 'bold' }}>
                      hosted experiences.
                    </Button>
                  </Link>
                </div>
              )}

              {comingUp.map(item => (
                <Card className="dashboard-comingUp" hoverable style={{ width: '100%' }} key={item.uid} onClick={() => setActiveEvent(item)}>
                  <Row align="middle">
                    <Col>
                      <img src={getEvent(item.uid).icon} width={25} />
                    </Col>
                    <Col style={{ paddingLeft: 17 }}>
                      <Text>{item.title}</Text>
                    </Col>
                    <Col style={{ marginLeft: 'auto' }}>
                      <Text type="secondary">Created: {day.unix(item.start * 10).format('ddd, MMM Do,  hh:mm a z')}</Text>
                    </Col>
                  </Row>
                </Card>
              ))}
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div
              className={
                pastEvents.map(comment => comment.feedback.length > 5)
                  ? 'dashboard-dashSection dashboard-comingUpcard-scroll'
                  : 'dashboard-dashSection dashboard-comingUpcard'
              }
            >
              <Row justify="space-between" align="middle" style={{ margin: '16px 20px' }}>
                <Col>
                  <Title level={4}>Feedback</Title>
                </Col>
                {/*
                <Col>
                  <Dropdown overlay={TimeMenu}>
                    <Button className="dashboard-metrics-filter" onClick={e => e.preventDefault()}>
                      {'This Week'} <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
                */}
              </Row>
              {pastEvents.map(pastEvent => (
                <Row>
                  <Feedback pastEvent={pastEvent} />
                </Row>
              ))}
            </div>
          </Col>
        </Row>

        {/* Events that are coming up */}
        <Modal
          title={<Title level={4}>{activeEvent.title}</Title>}
          centered
          onCancel={() => setActiveEvent({})}
          visible={activeEvent.title}
          confirmLoading={loading}
          footer={
            <Button danger key="delete" loading={loading} onClick={deleteEvent}>
              Delete
            </Button>
          }
        >
          <Text type="secondary" style={{ padding: 24, paddingLeft: 0 }}>
            Expires: {activeEvent.start && day.unix((activeEvent.start + 259200) * 10).format('ddd, MMM Do,  hh:mm a z')}
          </Text>
          <Title style={{ marginTop: 20 }} level={5}>
            Send to team
          </Title>
          <Input.Search
            placeholder="Event Link Appears Here"
            enterButton="Copy"
            value={`${window.location.origin}/event?id=${activeEvent._id}`}
            suffix={<CopyOutlined />}
            onSearch={value =>
              navigator.clipboard.writeText(value).then(() => {
                message.success('Copied!');
              })
            }
            style={{ width: '100%', marginTop: 10, display: 'inline-block' }}
          />
          <div style={{ paddingTop: 20 }}>
            <Text type="secondary">Created by: {activeEvent.host}</Text>
          </div>
        </Modal>
      </Content>
    </div>
  );
};

export default React.memo(Dashboard);

/*
const [scheduleEvent, setScheduleEvent] = useState({});
const [recommendedEvent, setRecommendedEvent] = useState({});

function filterEngagement(action) {
  if (action === 'all' && pastEvents.filtered) {
    setPastEvents({
      pastEvents: pastEvents,
    });
  } else if (action === 'one') {
    setPastEvents({
      pastEvents: pastEvents.filter(pastEvent => pastEvent.end > Math.round(Date.now() / 10000) - 789000),
      filtered: true,
    });
  } else if (action === 'three') {
    setPastEvents({
      pastEvents: pastEvents.filter(pastEvent => pastEvent.end > Math.round(Date.now() / 10000) - 2630000),
      filtered: true,
    });
  } else if (action === 'week') {
    setPastEvents({
      pastEvents: pastEvents.filter(pastEvent => pastEvent.end > Math.round(Date.now() / 10000) - 604825),
      filtered: true,
    });
  }
}

//Engagment Menu

const TimeMenu = (
  <Menu onSelect={(e) => {console.log(e)}} selectedKeys={}>
    <Menu.Item key="week" value="week">
      This Week
    </Menu.Item>
    <Menu.Item key="one" value="one">
      Last Month
    </Menu.Item>
    <Menu.Item key="three" value="three">
      Three Months
    </Menu.Item>
    <Menu.Item key="all" value="all">
      All Time
    </Menu.Item>
  </Menu>
);

<Title level={4} style={{ paddingTop: 20 }}>
  Schedule Next Event
</Title>

<Row className="engage-cardWrap" gutter={[16, 0]}>
  {comingUp.map(activity => (
    <Col xs={13} sm={12} md={8} lg={6} xl={6}>
      <Card className="dashboard-suggested-card" hoverable={true} key={activity.uid} onClick={() => setRecommendedEvent(activity)}>
        <Row align="middle">
          <Col>
            <img src={getEvent(activity.uid).icon} width={45} />
          </Col>
          <Col style={{ paddingLeft: 17 }}>
            <Title level={5}> {activity.title} </Title>
            <Tooltip title="Approximate Duration">
              <Text>
                <ClockCircleOutlined className="info-icon" />
                15 min
              </Text>
            </Tooltip>
            <Tooltip title="Number of People">
              <Text style={{ marginLeft: 6 }}>
                <TeamOutlined className="info-icon" />
                2+
              </Text>
            </Tooltip>
            <Tooltip title="Video Conferencing Required">{<VideoCameraOutlined className="info-icon" style={{ marginLeft: 10 }} />}</Tooltip>
          </Col>
          <Col style={{ marginLeft: 'auto' }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setScheduleEvent(activity)}></Button>
          </Col>
        </Row>
      </Card>
    </Col>
  ))}
</Row>


// Events that are recommended
<Modal
  title={
    <Row style={{ paddingRight: 20 }}>
      <Col>
        <img src={getEvent(recommendedEvent.uid).icon} width={45} />
      </Col>
      <Col style={{ paddingLeft: 10 }}>
        <Title level={4}>{recommendedEvent.title}</Title>
        <Tooltip title="Approximate Duration">
          <Text>
            <ClockCircleOutlined className="info-icon" />
            15 min
          </Text>
        </Tooltip>
        <Tooltip title="Number of People">
          <Text style={{ marginLeft: 6 }}>
            <TeamOutlined className="info-icon" />
            2+
          </Text>
        </Tooltip>
        <Tooltip title="Video Conferencing Required">{<VideoCameraOutlined className="info-icon" style={{ marginLeft: 10 }} />}</Tooltip>
      </Col>
      <Col style={{ marginLeft: 'auto' }}>
        <Button type="primary"> Let's Do It </Button>
      </Col>
    </Row>
  }
  centered
  onCancel={() => setRecommendedEvent({})}
  visible={recommendedEvent.title}
  confirmLoading={loading}
  footer={null}
>
  <Text>
    Doodle is an excellent way to get your team together to unwind and have some fun! Players take turns drawing a word that is prompted on
    the screen while others race to guess the word. The faster a player guesses, the more points they are awarded. The player with the most
    points at the end wins!
  </Text>
  <div style={{ width: 400, height: 300, backgroundColor: 'red', align: 'center', marginTop: 16 }}>VIDEO GOES HERE</div>
</Modal>

// Make and share the event link
<Modal
  title={<Title level={4}>Schedule Activity</Title>}
  centered
  onCancel={() => setScheduleEvent({})}
  visible={scheduleEvent.title}
  confirmLoading={loading}
  footer={null}
></Modal>

*/
