import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Functional Libraries

// Contexts
import { useAuth } from '../context/auth';

// Constants
import { catchMsg, passwordRules, emailRules } from '../constants';

// Components
import { Layout, Typography, Form, Input, InputNumber, Tooltip, Modal, Button, Row, Col, message, notification } from 'antd';

// Images/Icon
import {
  UserOutlined,
  IdcardOutlined,
  ShopOutlined,
  TeamOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';

// Styesheets
import './Less/settings.less';

// Sub components
const { Header, Content } = Layout;
const { Title, Text } = Typography;

export default function Settings() {
  let history = useHistory();
  const { api, idToken, setIdToken, accessToken, setAccessToken } = useAuth();
  const [changePwdVisible, setChangePwdVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [noChange, setNoChange] = useState(true);
  const [form] = Form.useForm();
  const [teamform] = Form.useForm();

  api.defaults.headers.common['Authorization'] = `idToken ${idToken} accessToken ${accessToken}`;

  useEffect(() => {
    api
      .get('profile')
      .then(res => {
        form.setFieldsValue({ name: res.data.name, lname: res.data.family_name, company: res.data.company, numEmp: res.data.numEmp });
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
  }, [form]);

  const updateProfile = values => {
    api
      .post('updateProfile', values)
      .then(res => {
        if (res.data.message === 'Success') message.success('Successfully updated information');
        else message.error(res.data.message);
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
  };

  const changePass = values => {
    setConfirmLoading(true);
    api
      .post('chngpwd', values)
      .then(res => {
        if (res.data.message === 'Success') message.success('Updated password successfully');
        else message.info(res.data.message);
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
    setTimeout(() => {
      setConfirmLoading(false);
      setChangePwdVisible(false);
    }, 2000);
  };

  const logout = () => {
    api
      .get('logout')
      .then(() => {
        setIdToken(null);
        setAccessToken(null);
        history.push('/signin');
      })
      .catch(() => {
        setIdToken(null);
        setAccessToken(null);
        history.push('/signin');
      });
  };

  // Add team members
  const sendLink = ({ email }) => {
    api
      .post('sendInvite', {
        email,
      })
      .then(res => {
        teamform.resetFields();
        if (res.data.exists) {
          notification.info({ message: email, description: 'Is already signed up. Contact team@afino.io if they need to be moved to a different team', duration: 0 });
        } else if (res.data.message === 'Success') {
          // Give success message
          message.success('Email invite sent');
        } else {
          message.error('We had trouble sending the link, please try again later');
        }
      })
      .catch(err => {
        message.error(catchMsg(err));
      });
  };

  return (
    <div style={{ minHeight: 'calc(100vh - 42px)' }} className="pageMargin">
      <Header style={{ padding: '20px 0' }}>
        <Title level={3} style={{ display: 'inline' }}>
          Profile
        </Title>
        <Button type="primary" shape="round" className="logout" onClick={logout}>
          Logout
        </Button>
      </Header>
      <Content>
        <Row>
          <Col xs={24} sm={12}>
            <Form name="profile_info" form={form} onFinish={updateProfile} onFieldsChange={() => setNoChange(false)}>
              <Title level={5}>First Name</Title>
              <Form.Item name="name" rules={[{ required: true, message: 'Please input your Name!' }]}>
                <Input
                  placeholder="Preferred Name"
                  prefix={<UserOutlined style={{ color: '#5D22A1' }} />}
                  className="settingsInput"
                  autocomplete="off"
                />
              </Form.Item>
              <Title level={5}>Last Name</Title>
              <Form.Item name="lname" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                <Input
                  placeholder="Last Name"
                  prefix={<IdcardOutlined style={{ color: '#5D22A1' }} />}
                  className="settingsInput"
                  autocomplete="off"
                />
              </Form.Item>
              <Title level={5}>Company</Title>
              <Tooltip title="Contact team@afino.io to change" placement="topLeft">
                <Form.Item name="company">
                  <Input
                    readOnly
                    placeholder="Company"
                    prefix={<ShopOutlined style={{ color: '#5D22A1' }} />}
                    className="settingsInput"
                    autocomplete="off"
                  />
                </Form.Item>
              </Tooltip>
              <Title level={5}>Team Size</Title>
              <Form.Item name="numEmp" rules={[{ required: true, message: 'How many people are part of your team?' }]}>
                <InputNumber prefix={<TeamOutlined className="site-form-item-icon" />} min={1} max={5000000} style={{ width: 120 }} />
              </Form.Item>
              <Form.Item>
                <Button disabled={noChange} type="primary" htmlType="submit" className="settingsInput">
                  Update
                </Button>
              </Form.Item>
            </Form>
            <Button type="primary" onClick={() => setChangePwdVisible(true)}>
              Change Password
            </Button>
          </Col>
          <Col xs={24} sm={12}>
            <div>
              <Title level={5}>Add To Team</Title>
              <Form autoComplete="off" form={teamform} onFinish={sendLink}>
                <Form.Item name="email" rules={emailRules} validateTrigger="onBlur">
                  <Input className="settingsInput" placeholder="Email" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Send Invite
                  </Button>
                </Form.Item>
              </Form>
              <Text type="secondary" style={{ fontStyle: 'italic' }}>
                Note: access links expire after 7 days
              </Text>
            </div>
          </Col>
        </Row>
        <Modal
          title="Change your password"
          centered
          visible={changePwdVisible}
          onOk={changePass}
          onClick={() => setChangePwdVisible(true)}
          onCancel={() => setChangePwdVisible(false)}
          footer={null}
        >
          <Form name="change_pwd" layout="vertical" onFinish={changePass}>
            <Form.Item name="oldpwd" rules={passwordRules} label="Old Password">
              <Input.Password placeholder="Old Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item name="pwd" rules={passwordRules} label="New Password">
              <Input.Password placeholder="New Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item name="confirm_new" rules={passwordRules} label="Confirm New Password">
              <Input.Password placeholder="Confirm New Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ float: 'right' }} loading={confirmLoading}>
                Change
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </div>
  );
}

/*<Form.List name="emails">
  {(fields, { add, remove }) => (
    <>
      {fields.map(field => (
        <Form.Item {...field} fieldKey={[field.fieldKey, 'email']} rules={emailRules}>
          <Input prefix={<MinusCircleOutlined onClick={() => remove(field.name)} />} placeholder="Email" />
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
          Add Email
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>*/
